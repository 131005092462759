import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { getPreviewsBySearch } from "../../api.js";

import GalleryCard from "../GalleryCard/GalleryCard.jsx";
import GalleryCardIcon from "../GalleryCard/GalleryCardIcon.jsx";
import InputTags from "../InputTags/InputTags.jsx";
import GalleryPaging from "../Gallery/GalleryPaging.jsx";

import CardGroup from "react-bootstrap/CardGroup";
import Badge from "react-bootstrap/Badge";
import Table from "react-bootstrap/Table";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";

const Gallery = (props) => {
  const numIcons = 30;
  const numCards = 12;
  const numListItems = 12;
  const { filterTags, setFilterTags, refresh } = props;
  const [page, setPage] = useState(1);
  const [length, setLength] = useState(numCards);
  const [previews, setPreviews] = useState([]);
  const [galleryType, setGalleryType] = useState("cards");
  const [numMatches, setNumMatches] = useState(0);

  useEffect(() => {
    getPreviewsBySearch(
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      filterTags,
      page,
      length
    ).then((res) => {
      if (res.matches) {
        setNumMatches(res.matches);
      }
      setPreviews(Object.values(res.submissionPreviews).filter(p => p.first !== "ND"));
    });
  }, [page, length, filterTags, refresh]);

  const handleTagClick = (e, tag) => {
    e.preventDefault();
    e.stopPropagation();
    setPage(1);
    setFilterTags([...filterTags, tag]);
  };

  const toggleView = (val) => {
    setGalleryType(val);
    setLength(
      val === "icon" ? numIcons : val === "cards" ? numCards : numListItems
    );
    setPage(1);
  };

  const getType = (type) => {
    return type === "other" ? "written" : type;
  };

  const navigate = useNavigate();

  return (
    <div className="m-5">
      <strong>Filter by tags?</strong>
      <InputTags
        tags={filterTags}
        setTags={setFilterTags}
        controlId="filtertags"
      />

      <br />
      <div className="text-right">
        <Button variant="primary" onClick={() => navigate("/search")}>
          Advanced Search
        </Button>
      </div>

      <fieldset>
        <div className="text-center m-3">
          <legend>View type</legend>
          <ButtonGroup
            type="radio"
            name="gal"
            value={galleryType}
            onChange={toggleView}
          >
            <Button value={"cards"}>Card View</Button>
            <Button value={"list"}>List View</Button>
            <Button value={"icon"}>Icon View</Button>
          </ButtonGroup>
        </div>
      </fieldset>
      <div className="m-5 gallery-wrap">
        {numMatches > 0 ? (
          <>
            {galleryType === "cards" ? (
              <CardGroup className="gallery ">
                {previews.map((plan, i) => (
                  <li key={i} style={{ listStyle: "none" }}>
                    <GalleryCard
                      title={plan.title || "TITLE"}
                      name={plan.first + " " + plan.last}
                      loc={plan.city}
                      id={plan.type[0] + plan.id || "id001"}
                      type={plan.type || "coi"}
                      datetime={
                        new Date(plan.datetime).toLocaleDateString() ||
                        "3/25/2021"
                      }
                      img={plan.img || ""}
                      link={plan.link || ""}
                      numberOfComments={plan.numberofcomments || 0}
                      tags={plan.tags || []}
                      filterTags={filterTags}
                      setFilterTags={setFilterTags}
                      handleTagClick={handleTagClick}
                    />
                  </li>
                ))}
              </CardGroup>
            ) : galleryType === "icon" ? (
              <CardGroup className="gallery icons">
                {previews.map((plan, i) => (
                  <li key={i} style={{ listStyle: "none" }}>
                    <GalleryCardIcon
                      title={plan.title || "TITLE"}
                      id={plan.type[0] + plan.id || "id001"}
                      type={plan.type || "coi"}
                      link={plan.link || ""}
                    />
                  </li>
                ))}
              </CardGroup>
            ) : (
              <Table hover class="list-view">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Submitter</th>
                    <th>Date</th>
                    <th>ID</th>
                    <th>Tags</th>
                    <th>Comments</th>
                  </tr>
                </thead>
                <tbody>
                  {previews.map((plan, i) => (
                    <tr
                      onClick={() =>
                        navigate("/submission/" + plan.type[0] + plan.id)
                      }
                      key={i}
                    >
                      <td className={getType(plan.type)}>
                        <a
                          className="preview"
                          href={"/submission/" + plan.type[0] + plan.id}
                        >
                          <b>{plan.title}</b>
                        </a>
                      </td>
                      <td>
                        {plan.first} {plan.last}
                        {plan.city ? <> – {plan.city}</> : ""}
                      </td>
                      <td>
                        {new Date(plan.datetime).toLocaleDateString() ||
                          "3/25/2021"}
                      </td>
                      <td>{plan.type[0] + plan.id}</td>
                      <td>
                        <div className="tags-expo">
                          {(plan.tags || []).map((tag, i) => (
                            <a
                              className="post-tag"
                              onClick={(e) => handleTagClick(e, tag)}
                              key={i}
                            >
                              #{tag}
                            </a>
                          ))}
                        </div>
                      </td>
                      <td className="text-muted">
                        Comments:{" "}
                        <Badge variant={getType(plan.type)}>
                          {plan.numberofcomments || 0}
                        </Badge>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </>
        ) : (
          <div className="text-center">
            Nothing has been submitted so far.
            <br />
            <br />
          </div>
        )}
        <GalleryPaging
          page={page}
          setPage={setPage}
          length={length}
          numMatches={numMatches}
        />
      </div>
    </div>
  );
};

export default Gallery;
