const locations = [
    'Acme Township',
    'Ada Township',
    'Adams Township',
    'Addison Township',
    'Adrian City',
    'Adrian Township',
    'Aetna Township',
    'Akron Township',
    'Alabaster Township',
    'Alaiedon Township',
    'Alamo Township',
    'Albee Township',
    'Albert Township',
    'Albion City',
    'Albion Township',
    'Alcona Township',
    'Alcona',
    'Algansee Township',
    'Alger',
    'Algoma Township',
    'Algonac City',
    'Allegan City',
    'Allegan Township',
    'Allegan',
    'Allen Park City',
    'Allen Township',
    'Allendale Township',
    'Allis Township',
    'Allouez Township',
    'Alma City',
    'Almena Township',
    'Almer Township',
    'Almira Township',
    'Almont Township',
    'Aloha Township',
    'Alpena City',
    'Alpena Township',
    'Alpena',
    'Alpine Township',
    'Amber Township',
    'Amboy Township',
    'Ann Arbor City',
    'Ann Arbor Township',
    'Antioch Township',
    'Antrim County',
    'Antrim Township',
    'Antrim',
    'Antwerp Township',
    'Arbela Township',
    'Arcada Township',
    'Arcadia Township',
    'Arenac County',
    'Arenac Township',
    'Arenac',
    'Argentine Township',
    'Argyle Township',
    'Arlington Township',
    'Armada Township',
    'Arthur Township',
    'Arvon Township',
    'Ash Township',
    'Ashland Township',
    'Assyria Township',
    'Athens Township',
    'Atlas Township',
    'Attica Township',
    'Au Gres City',
    'Au Gres Township',
    'Au Sable Township',
    'Au Train Township',
    'Auburn City',
    'Auburn Hills City',
    'Augusta Township',
    'Aurelius Township',
    'Austin Township',
    'Avery Township',
    'Backus Township',
    'Bad Axe City',
    'Bagley Township',
    'Bainbridge Township',
    'Baldwin Township',
    'Baltimore Township',
    'Bangor City',
    'Bangor Township',
    'Banks Township',
    'Baraga Township',
    'Baraga',
    'Bark River Township',
    'Baroda Township',
    'Barry Township',
    'Barry',
    'Barton Township',
    'Batavia Township',
    'Bates Township',
    'Bath Township',
    'Battle Creek City',
    'Bay City City',
    'Bay De Noc Township',
    'Bay Mills Township',
    'Bay Township',
    'Bay',
    'Bear Creek Township',
    'Bear Lake Township',
    'Bearinger Township',
    'Beaugrand Township',
    'Beaver Creek Township',
    'Beaver Township',
    'Beaverton City',
    'Beaverton Township',
    'Bedford Township',
    'Belding City',
    'Belknap Township',
    'Belleville City',
    'Bellevue Township',
    'Belvidere Township',
    'Bengal Township',
    'Bennington Township',
    'Benona Township',
    'Bentley Township',
    'Benton Charter Township',
    'Benton Harbor City',
    'Benton Township',
    'Benzie',
    'Benzonia Township',
    'Bergland Township',
    'Berkley City',
    'Berlin Township',
    'Berrien Township',
    'Berrien',
    'Bertrand Township',
    'Bessemer City',
    'Bessemer Township',
    'Bethany Township',
    'Bethel Township',
    'Big Creek Township',
    'Big Prairie Township',
    'Big Rapids City',
    'Big Rapids Township',
    'Billings Township',
    'Bingham Township',
    'Birch Run Township',
    'Birmingham City',
    'Bismarck Township',
    'Blackman Township',
    'Blaine Township',
    'Blair Township',
    'Blendon Township',
    'Bliss Township',
    'Blissfield Township',
    'Bloomer Township',
    'Bloomfield Hills City',
    'Bloomfield Township',
    'Bloomingdale Township',
    'Blue Lake Township',
    'Blumfield Township',
    'Boardman Township',
    'Bohemia Township',
    'Bois Blanc Township',
    'Boon Township',
    'Boston Township',
    'Bourret Township',
    'Bowne Township',
    'Boyne City City',
    'Boyne Valley Township',
    'Brady Township',
    'Brampton Township',
    'Branch Township',
    'Branch',
    'Brandon Township',
    'Brant Township',
    'Breen Township',
    'Breitung Township',
    'Brevort Township',
    'Bridgehampton Township',
    'Bridgeport Township',
    'Bridgeton Township',
    'Bridgewater Township',
    'Bridgman City',
    'Brighton Charter Township',
    'Brighton City',
    'Briley Township',
    'Brockway Township',
    'Bronson City',
    'Bronson Township',
    'Brookfield Township',
    'Brooks Township',
    'Broomfield Township',
    'Brown City City',
    'Brown Township',
    'Brownstown Township',
    'Bruce Township',
    'Buchanan City',
    'Buchanan Township',
    'Buckeye Township',
    'Buel Township',
    'Buena Vista Charter Township',
    'Bunker Hill Township',
    'Burdell Township',
    'Burleigh Township',
    'Burlington Township',
    'Burns Township',
    'Burnside Township',
    'Burr Oak Township',
    'Burt Township',
    'Burtchville Township',
    'Burton City',
    'Bushnell Township',
    'Butler Township',
    'Butman Township',
    'Butterfield Township',
    'Byron Township',
    'Cadillac City',
    'Caldwell Township',
    'Caledonia Charter Township',
    'Caledonia Township',
    'Calhoun',
    'California Township',
    'Calumet Township',
    'Calvin Township',
    'Cambria Township',
    'Cambridge Township',
    'Camden Township',
    'Campbell Township',
    'Cannon Township',
    'Canton Township',
    'Carlton Township',
    'Carmel Township',
    'Caro City',
    'Carp Lake Township',
    'Carrollton Township',
    'Carson City City',
    'Cascade Township',
    'Casco Township',
    'Case Township',
    'Caseville City',
    'Caseville Township',
    'Casnovia Township',
    'Caspian City',
    'Cass',
    'Castleton Township',
    'Cato Township',
    'Cedar Creek Township',
    'Cedar Springs City',
    'Cedar Township',
    'Cedarville Township',
    'Center Line City',
    'Center Township',
    'Centerville Township',
    'Central Lake Township',
    'Champion Township',
    'Chandler Township',
    'Chapin Township',
    'Charleston Township',
    'Charlevoix City',
    'Charlevoix Township',
    'Charlevoix',
    'Charlotte City',
    'Charlton Township',
    'Chase Township',
    'Chassell Township',
    'Cheboygan City',
    'Cheboygan',
    'Chelsea City',
    'Cherry Grove Township',
    'Cherry Valley Township',
    'Chesaning Township',
    'Cheshire Township',
    'Chester Township',
    'Chesterfield Township',
    'Chestonia Township',
    'Chikaming Township',
    'China Township',
    'Chippewa County',
    'Chippewa Township',
    'Chippewa',
    'Chocolay Township',
    'Churchill Township',
    'Clam Lake Township',
    'Clam Union Township',
    'Clare City',
    'Clare',
    'Clarence Township',
    'Clarendon Township',
    'Clark Township',
    'Clawson City',
    'Clay Township',
    'Claybanks Township',
    'Clayton Township',
    'Clearwater Township',
    'Clement Township',
    'Cleon Township',
    'Cleveland Township',
    'Climax Township',
    'Clinton County',
    'Clinton Township',
    'Clinton',
    'Clio City',
    'Clyde Township',
    'Coe Township',
    'Cohoctah Township',
    'Coldsprings Township',
    'Coldwater City',
    'Coldwater Township',
    'Coleman City',
    'Colfax Township',
    'Coloma Charter Township',
    'Coloma City',
    'Colon Township',
    'Columbia Township',
    'Columbus Township',
    'Comins Township',
    'Commerce Township',
    'Comstock Township',
    'Concord Township',
    'Constantine Township',
    'Convis Township',
    'Conway Township',
    'Cooper Township',
    'Coopersville City',
    'Cornell Township',
    'Corunna City',
    'Corwith Township',
    'Cottrellville Township',
    'Courtland Township',
    'Covert Township',
    'Covington Township',
    'Crawford',
    'Crockery Township',
    'Cross Village Township',
    'Croswell City',
    'Croton Township',
    'Crystal Falls City',
    'Crystal Falls Township',
    'Crystal Lake Township',
    'Crystal Township',
    'Cumming Township',
    'Curtis Township',
    'Custer Township',
    'Dafter Township',
    'Daggett Township',
    'Dallas Township',
    'Dalton Township',
    'Danby Township',
    'Davison City',
    'Davison Township',
    'Day Township',
    'Dayton Township',
    'De Witt City',
    'De Witt Township',
    'Dearborn City',
    'Dearborn Heights City',
    'Decatur Township',
    'Deep River Township',
    'Deerfield Township',
    'Delaware Township',
    'Delhi Charter Township',
    'Delta Charter Township',
    'Delta',
    'Denmark Township',
    'Denton Township',
    'Denver Township',
    'Detour Township',
    'Detroit City',
    'Dexter City',
    'Dexter Township',
    'Dickinson',
    'Dickson Township',
    'Dorr Township',
    'Douglass Township',
    'Dover Township',
    'Dowagiac City',
    'Doyle Township',
    'Drummond Township',
    'Dryden Township',
    'Duncan Township',
    'Dundee Township',
    'Duplain Township',
    'Durand City',
    'Dwight Township',
    'Eagle Harbor Township',
    'Eagle Township',
    'East Bay Township',
    'East China Township',
    'East Grand Rapids City',
    'East Jordan City',
    'East Lansing City',
    'East Tawas City',
    'Easton Township',
    'Eastpointe City',
    'Eaton Rapids City',
    'Eaton Rapids Township',
    'Eaton Township',
    'Eaton',
    'Echo Township',
    'Eckford Township',
    'Ecorse City',
    'Eden Township',
    'Edenville Township',
    'Edwards Township',
    'Egelston Township',
    'Elba Township',
    'Elbridge Township',
    'Elk Rapids Township',
    'Elk Township',
    'Elkland Township',
    'Ellington Township',
    'Ellis Township',
    'Ellsworth Township',
    'Elm River Township',
    'Elmer Township',
    'Elmira Township',
    'Elmwood Township',
    'Ely Township',
    'Emerson Township',
    'Emmet',
    'Emmett Township',
    'Empire Township',
    'Ensign Township',
    'Ensley Township',
    'Enterprise Township',
    'Erie Township',
    'Erwin Township',
    'Escanaba City',
    'Escanaba Township',
    'Essex Township',
    'Essexville City',
    'Eureka Township',
    'Evangeline Township',
    'Evart City',
    'Evart Township',
    'Eveline Township',
    'Everett Township',
    'Evergreen Township',
    'Ewing Township',
    'Excelsior Township',
    'Exeter Township',
    'Fabius Township',
    'Fairbanks Township',
    'Fairfield Township',
    'Fairgrove Township',
    'Fairhaven Township',
    'Fairplain Township',
    'Faithorn Township',
    'Farmington City',
    'Farmington Hills City',
    'Fawn River Township',
    'Fayette Township',
    'Felch Township',
    'Fennville City',
    'Fenton City',
    'Fenton Township',
    'Ferndale City',
    'Ferris Township',
    'Ferry Township',
    'Ferrysburg City',
    'Fife Lake Township',
    'Filer Township',
    'Fillmore Township',
    'Flat Rock City',
    'Flint City',
    'Flint Township',
    'Florence Township',
    'Flowerfield Township',
    'Flushing City',
    'Flushing Township',
    'Flynn Township',
    'Ford River Township',
    'Forest Home Township',
    'Forest Township',
    'Forester Township',
    'Fork Township',
    'Forsyth Township',
    'Fort Gratiot Township',
    'Foster Township',
    'Frankenlust Township',
    'Frankenmuth City',
    'Frankenmuth Township',
    'Frankfort City',
    'Franklin Township',
    'Fraser City',
    'Fraser Township',
    'Frederic Township',
    'Fredonia Township',
    'Free Soil Township',
    'Freedom Township',
    'Freeman Township',
    'Fremont City',
    'Fremont Township',
    'Frenchtown Township',
    'Friendship Township',
    'Frost Township',
    'Fruitland Township',
    'Fruitport Township',
    'Fulton Township',
    'Gaastra City',
    'Gaines Township',
    'Galesburg City',
    'Galien Township',
    'Ganges Township',
    'Garden City City',
    'Garden Township',
    'Garfield Township',
    'Gaylord City',
    'Genesee Township',
    'Genesee',
    'Geneva Township',
    'Genoa Township',
    'Georgetown Township',
    'Germfask Township',
    'Gerrish Township',
    'Gibraltar City',
    'Gibson Township',
    'Gilead Township',
    'Gilford Township',
    'Gilmore Township',
    'Girard Township',
    'Gladstone City',
    'Gladwin City',
    'Gladwin Township',
    'Gladwin',
    'Glen Arbor Township',
    'Gobles City',
    'Gogebic',
    'Golden Township',
    'Goodar Township',
    'Goodland Township',
    'Goodwell Township',
    'Gore Township',
    'Gourley Township',
    'Grand Blanc City',
    'Grand Blanc Township',
    'Grand Haven City',
    'Grand Haven Township',
    'Grand Island Township',
    'Grand Ledge City',
    'Grand Rapids Charter Township',
    'Grand Rapids City',
    'Grand Traverse',
    'Grandville City',
    'Grant City',
    'Grant Township',
    'Grass Lake Charter Township',
    'Gratiot',
    'Grattan Township',
    'Grayling Charter Township',
    'Grayling City',
    'Green Lake Township',
    'Green Oak Township',
    'Green Township',
    'Greenbush Township',
    'Greendale Township',
    'Greenland Township',
    'Greenleaf Township',
    'Greenville City',
    'Greenwood Township',
    'Grim Township',
    'Grosse Ile Township',
    'Grosse Pointe City',
    'Grosse Pointe Farms City',
    'Grosse Pointe Park City',
    'Grosse Pointe Shores City',
    'Grosse Pointe Woods City',
    'Grout Township',
    'Groveland Township',
    'Gunplain Township',
    'Gustin Township',
    'Hadley Township',
    'Hagar Township',
    'Haight Township',
    'Hamburg Township',
    'Hamilton Township',
    'Hamlin Township',
    'Hampton Township',
    'Hamtramck City',
    'Hancock City',
    'Hancock Township',
    'Handy Township',
    'Hanover Township',
    'Harbor Beach City',
    'Harbor Springs City',
    'Haring Township',
    'Harper Woods City',
    'Harris Township',
    'Harrison City',
    'Harrison Township',
    'Harrisville City',
    'Harrisville Township',
    'Hart City',
    'Hart Township',
    'Hartford City',
    'Hartford Township',
    'Hartland Township',
    'Hartwick Township',
    'Hastings City',
    'Hastings Township',
    'Hatton Township',
    'Hawes Township',
    'Hay Township',
    'Hayes Township',
    'Haynes Township',
    'Hazel Park City',
    'Hazelton Township',
    'Heath Township',
    'Hebron Township',
    'Helena Township',
    'Hematite Township',
    'Henderson Township',
    'Hendricks Township',
    'Henrietta Township',
    'Hersey Township',
    'Hiawatha Township',
    'Higgins Township',
    'Highland Park City',
    'Highland Township',
    'Hill Township',
    'Hillman Township',
    'Hillsdale City',
    'Hillsdale Township',
    'Hillsdale',
    'Hinton Township',
    'Holland City',
    'Holland Township',
    'Holly Township',
    'Holmes Township',
    'Holton Township',
    'Home Township',
    'Homer Township',
    'Homestead Township',
    'Hope Township',
    'Hopkins Township',
    'Horton Township',
    'Houghton City',
    'Houghton Township',
    'Houghton',
    'Howard Township',
    'Howell City',
    'Howell Township',
    'Hudson City',
    'Hudson Township',
    'Hudsonville City',
    'Hulbert Township',
    'Humboldt Township',
    'Hume Township',
    'Huntington Woods City',
    'Huron Township',
    'Huron',
    'Ida Township',
    'Imlay City City',
    'Imlay Township',
    'Independence Township',
    'Indianfields Township',
    'Ingallston Township',
    'Ingersoll Township',
    'Ingham Township',
    'Ingham',
    'Inkster City',
    'Inland Township',
    'Interior Township',
    'Inverness Township',
    'Inwood Township',
    'Ionia City',
    'Ionia Township',
    'Ionia',
    'Iosco Township',
    'Iosco',
    'Ira Township',
    'Iron Mountain City',
    'Iron River City',
    'Iron River Township',
    'Iron',
    'Ironwood City',
    'Ironwood Township',
    'Irving Township',
    'Isabella Township',
    'Isabella',
    'Ishpeming City',
    'Ishpeming Township',
    'Ithaca City',
    'Jackson City',
    'Jackson',
    'James Township',
    'Jamestown Township',
    'Jasper Township',
    'Jefferson Township',
    'Jerome Township',
    'Johnstown Township',
    'Jonesfield Township',
    'Jonesville City',
    'Jordan Township',
    'Joyfield Township',
    'Juniata Township',
    'Kalamazoo City',
    'Kalamazoo Township',
    'Kalamazoo',
    'Kalamo Township',
    'Kalkaska Township',
    'Kalkaska',
    'Kasson Township',
    'Kawkawlin Township',
    'Kearney Township',
    'Keego Harbor City',
    'Keeler Township',
    'Keene Township',
    'Kenockee Township',
    'Kent',
    'Kentwood City',
    'Keweenaw',
    'Kimball Township',
    'Kinderhook Township',
    'Kingsford City',
    'Kingston Township',
    'Kinross Township',
    'Klacking Township',
    'Kochville Township',
    'Koehler Township',
    'Koylton Township',
    'Krakow Township',
    'La Grange Township',
    'La Salle Township',
    'Lafayette Township',
    'Laingsburg City',
    'Laird Township',
    'Lake Angelus City',
    'Lake Charter Township',
    'Lake City City',
    'Lake Township',
    'Lake',
    'Lakefield Township',
    'Laketon Township',
    'Laketown Township',
    'Lamotte Township',
    'Lanse Township',
    'Lansing City',
    'Lansing Township',
    'Lapeer City',
    'Lapeer Township',
    'Lapeer',
    'Larkin Township',
    'Lathrup Village City',
    'Lawrence Township',
    'Le Roy Township',
    'Leavitt Township',
    'Lebanon Township',
    'Lee Township',
    'Leelanau Township',
    'Leelanau',
    'Leighton Township',
    'Leland Township',
    'Lenawee',
    'Lenox Township',
    'Leoni Township',
    'Leonidas Township',
    'Leroy Township',
    'Leslie City',
    'Leslie Township',
    'Lexington Township',
    'Liberty Township',
    'Lilley Township',
    'Lima Township',
    'Limestone Township',
    'Lincoln Charter Township',
    'Lincoln Park City',
    'Lincoln Township',
    'Linden City',
    'Litchfield City',
    'Litchfield Township',
    'Little Traverse Township',
    'Littlefield Township',
    'Livingston Township',
    'Livingston',
    'Livonia City',
    'Locke Township',
    'Lockport Township',
    'Lodi Township',
    'Logan Township',
    'London Township',
    'Long Lake Township',
    'Long Rapids Township',
    'Loud Township',
    'Lovells Township',
    'Lowell City',
    'Lowell Township',
    'Luce',
    'Ludington City',
    'Luna Pier City',
    'Lyndon Township',
    'Lynn Township',
    'Lyon Township',
    'Lyons Township',
    'Mackinac Island City',
    'Mackinac',
    'Mackinaw Township',
    'Macomb Township',
    'Macomb',
    'Macon Township',
    'Madison Charter Township',
    'Madison Heights City',
    'Mancelona Township',
    'Manchester Township',
    'Manistee City',
    'Manistee Township',
    'Manistee',
    'Manistique City',
    'Manistique Township',
    'Manlius Township',
    'Mansfield Township',
    'Manton City',
    'Maple Forest Township',
    'Maple Grove Township',
    'Maple Ridge Township',
    'Maple River Township',
    'Maple Valley Township',
    'Marathon Township',
    'Marcellus Township',
    'Marengo Township',
    'Marenisco Township',
    'Marilla Township',
    'Marine City City',
    'Marion Township',
    'Markey Township',
    'Marlette City',
    'Marlette Township',
    'Marquette City',
    'Marquette Township',
    'Marquette',
    'Marshall City',
    'Marshall Township',
    'Martin Township',
    'Martiny Township',
    'Marysville City',
    'Mason City',
    'Mason Township',
    'Mason',
    'Masonville Township',
    'Mastodon Township',
    'Matchwood Township',
    'Mathias Township',
    'Matteson Township',
    'Mayfield Township',
    'Mcbain City',
    'Mckinley Township',
    'Mcmillan Township',
    'Meade Township',
    'Mecosta Township',
    'Mecosta',
    'Medina Township',
    'Mellen Township',
    'Melrose Township',
    'Melvindale City',
    'Memphis City',
    'Mendon Township',
    'Menominee City',
    'Menominee Township',
    'Menominee',
    'Mentor Township',
    'Meridian Township',
    'Merrill Township',
    'Merritt Township',
    'Metamora Township',
    'Metz Township',
    'Meyer Township',
    'Michigamme Township',
    'Middle Branch Township',
    'Middlebury Township',
    'Midland City',
    'Midland Township',
    'Midland',
    'Mikado Township',
    'Milan City',
    'Milan Township',
    'Milford Township',
    'Millbrook Township',
    'Millen Township',
    'Millington Township',
    'Mills Township',
    'Milton Township',
    'Minden Township',
    'Missaukee',
    'Mitchell Township',
    'Moffatt Township',
    'Moltke Township',
    'Monitor Township',
    'Monroe City',
    'Monroe Township',
    'Monroe',
    'Montague City',
    'Montague Township',
    'Montcalm Township',
    'Montcalm',
    'Monterey Township',
    'Montmorency Township',
    'Montmorency',
    'Montrose City',
    'Montrose Township',
    'Moore Township',
    'Moorland Township',
    'Moran Township',
    'Morenci City',
    'Morton Township',
    'Moscow Township',
    'Mottville Township',
    'Mount Clemens City',
    'Mount Forest Township',
    'Mount Haley Township',
    'Mount Morris City',
    'Mount Morris Township',
    'Mount Pleasant City',
    'Mueller Township',
    'Mullett Township',
    'Mundy Township',
    'Munising City',
    'Munising Township',
    'Munro Township',
    'Muskegon City',
    'Muskegon Heights City',
    'Muskegon Township',
    'Muskegon',
    'Mussey Township',
    'Nadeau Township',
    'Nahma Township',
    'Napoleon Township',
    'Negaunee City',
    'Negaunee Township',
    'Nelson Township',
    'Nester Township',
    'New Baltimore City',
    'New Buffalo City',
    'New Buffalo Township',
    'New Haven Township',
    'Newark Township',
    'Newaygo City',
    'Newaygo',
    'Newberg Township',
    'Newfield Township',
    'Newkirk Township',
    'Newton Township',
    'Niles Charter Township',
    'Niles City',
    'Noble Township',
    'Norman Township',
    'North Allis Township',
    'North Branch Township',
    'North Muskegon City',
    'North Plains Township',
    'North Shade Township',
    'North Star Township',
    'Northfield Township',
    'Northville City',
    'Northville Township',
    'Norton Shores City',
    'Norvell Township',
    'Norway City',
    'Norway Township',
    'Norwich Township',
    'Norwood Township',
    'Nottawa Township',
    'Novesta Township',
    'Novi City',
    'Novi Township',
    'Nunda Township',
    'Oak Park City',
    'Oakfield Township',
    'Oakland Charter Township',
    'Oakland County',
    'Oakland',
    'Oceana',
    'Oceola Township',
    'Ocqueoc Township',
    'Odessa Township',
    'Ogden Township',
    'Ogemaw Township',
    'Ogemaw',
    'Olive Township',
    'Oliver Township',
    'Olivet City',
    'Omer City',
    'Onaway City',
    'Oneida Charter Township',
    'Onekama Township',
    'Onondaga Township',
    'Onota Township',
    'Ontonagon Township',
    'Ontonagon',
    'Ontwa Township',
    'Orange Township',
    'Orangeville Township',
    'Orchard Lake Village City',
    'Oregon Township',
    'Orient Township',
    'Orion Township',
    'Orleans Township',
    'Oronoko Charter Township',
    'Osceola Township',
    'Osceola',
    'Oscoda Township',
    'Oscoda',
    'Oshtemo Township',
    'Ossineke Township',
    'Otisco Township',
    'Otsego City',
    'Otsego Lake Township',
    'Otsego Township',
    'Otsego',
    'Ottawa',
    'Otto Township',
    'Overisel Township',
    'Ovid City',
    'Ovid Township',
    'Owosso City',
    'Owosso Township',
    'Oxford Township',
    'Palmyra Township',
    'Paradise Township',
    'Parchment City',
    'Paris Township',
    'Park Township',
    'Parma Township',
    'Pavilion Township',
    'Paw Paw Township',
    'Peacock Township',
    'Peaine Township',
    'Peninsula Township',
    'Penn Township',
    'Pennfield Township',
    'Pentland Township',
    'Pentwater Township',
    'Pere Marquette Charter Twp',
    'Perry City',
    'Perry Township',
    'Petersburg City',
    'Petoskey City',
    'Pickford Township',
    'Pierson Township',
    'Pinconning City',
    'Pinconning Township',
    'Pine Grove Township',
    'Pine River Township',
    'Pine Township',
    'Pinora Township',
    'Pioneer Township',
    'Pipestone Township',
    'Pittsfield Charter Township',
    'Pittsford Township',
    'Plainfield Township',
    'Plainwell City',
    'Platte Township',
    'Pleasant Plains Township',
    'Pleasant Ridge City',
    'Pleasanton Township',
    'Pleasantview Township',
    'Plymouth City',
    'Plymouth Township',
    'Pointe Aux Barques Township',
    'Pokagon Township',
    'Polkton Township',
    'Pontiac City',
    'Port Austin Township',
    'Port Huron City',
    'Port Huron Township',
    'Port Sheldon Township',
    'Portage City',
    'Portage Township',
    'Porter Township',
    'Portland City',
    'Portland Township',
    'Portsmouth Township',
    'Posen Township',
    'Potterville City',
    'Powell Township',
    'Prairie Ronde Township',
    'Prairieville Township',
    'Presque Isle Township',
    'Presque Isle',
    'Pulaski Township',
    'Pulawski Township',
    'Putnam Township',
    'Quincy Township',
    'Raber Township',
    'Raisin Township',
    'Raisinville Township',
    'Ransom Township',
    'Rapid River Township',
    'Ravenna Township',
    'Ray Township',
    'Reading City',
    'Reading Township',
    'Readmond Township',
    'Redding Township',
    'Redford Township',
    'Reed City City',
    'Reeder Township',
    'Reno Township',
    'Republic Township',
    'Resort Township',
    'Reynolds Township',
    'Rich Township',
    'Richfield Township',
    'Richland Township',
    'Richmond City',
    'Richmond Township',
    'Ridgeway Township',
    'Riga Township',
    'Riley Township',
    'River Rouge City',
    'Riverside Township',
    'Riverton Township',
    'Riverview City',
    'Rives Township',
    'Robinson Township',
    'Rochester City',
    'Rochester Hills City',
    'Rock River Township',
    'Rockford City',
    'Rockland Township',
    'Rockwood City',
    'Rogers City City',
    'Rogers Township',
    'Rolland Township',
    'Rollin Township',
    'Rome Township',
    'Romulus City',
    'Ronald Township',
    'Roosevelt Park City',
    'Roscommon Township',
    'Roscommon',
    'Rose City City',
    'Rose Lake Township',
    'Rose Township',
    'Roseville City',
    'Ross Township',
    'Roxand Township',
    'Royal Oak City',
    'Royal Oak Township',
    'Royalton Township',
    'Rubicon Township',
    'Rudyard Township',
    'Rush Township',
    'Rust Township',
    'Rutland Township',
    'Sage Township',
    'Saginaw City',
    'Saginaw Township',
    'Saginaw',
    'Sagola Township',
    'Salem Township',
    'Saline City',
    'Saline Township',
    'Sanborn Township',
    'Sand Beach Township',
    'Sands Township',
    'Sandstone Township',
    'Sandusky City',
    'Sanilac Township',
    'Sanilac',
    'Sauble Township',
    'Saugatuck City',
    'Saugatuck Township',
    'Sault Ste Marie City',
    'Schoolcraft Township',
    'Schoolcraft',
    'Scio Township',
    'Sciota Township',
    'Scipio Township',
    'Scottville City',
    'Sebewa Township',
    'Sebewaing Township',
    'Secord Township',
    'Selma Township',
    'Seneca Township',
    'Seney Township',
    'Seville Township',
    'Sharon Township',
    'Shelby Charter Township',
    'Shelby Township',
    'Sheridan Charter Township',
    'Sheridan Township',
    'Sherman Township',
    'Sherwood Township',
    'Shiawassee Township',
    'Shiawassee',
    'Sidney Township',
    'Sigel Township',
    'Silver Creek Township',
    'Sims Township',
    'Skandia Township',
    'Slagle Township',
    'Sodus Township',
    'Solon Township',
    'Somerset Township',
    'Soo Township',
    'South Arm Township',
    'South Branch Township',
    'South Haven City',
    'South Haven Township',
    'South Lyon City',
    'Southfield City',
    'Southfield Township',
    'Southgate City',
    'Spalding Township',
    'Sparta Township',
    'Spaulding Township',
    'Speaker Township',
    'Spencer Township',
    'Spring Arbor Township',
    'Spring Lake Township',
    'Springdale Township',
    'Springfield City',
    'Springfield Township',
    'Springport Township',
    'Springvale Township',
    'Springville Township',
    'Spurr Township',
    'St Charles Township',
    'St Clair City',
    'St Clair Shores City',
    'St Clair Township',
    'St Ignace City',
    'St Ignace Township',
    'St James Township',
    'St Johns City',
    'St Joseph Charter Township',
    'St Joseph City',
    'St Louis City',
    'St. Clair',
    'St. Joseph',
    'Stambaugh Township',
    'Standish City',
    'Standish Township',
    'Stannard Township',
    'Stanton City',
    'Stanton Township',
    'Star Township',
    'Stephenson City',
    'Stephenson Township',
    'Sterling Heights City',
    'Stockbridge Township',
    'Stronach Township',
    'Sturgis City',
    'Sturgis Township',
    'Sugar Island Township',
    'Sullivan Township',
    'Summerfield Township',
    'Summit Township',
    'Sumner Township',
    'Sumpter Township',
    'Sunfield Township',
    'Superior Township',
    'Surrey Township',
    'Suttons Bay Township',
    'Swan Creek Township',
    'Swartz Creek City',
    'Sweetwater Township',
    'Sylvan Lake City',
    'Sylvan Township',
    'Tallmadge Township',
    'Tawas City City',
    'Tawas Township',
    'Taylor City',
    'Taymouth Township',
    'Tecumseh City',
    'Tecumseh Township',
    'Tekonsha Township',
    'Texas Township',
    'Thetford Township',
    'Thomas Township',
    'Thompson Township',
    'Thornapple Township',
    'Three Oaks Township',
    'Three Rivers City',
    'Tilden Township',
    'Tittabawassee Township',
    'Tobacco Township',
    'Tompkins Township',
    'Torch Lake Township',
    'Traverse City City',
    'Trenton City',
    'Trout Lake Township',
    'Trowbridge Township',
    'Troy City',
    'Troy Township',
    'Turin Township',
    'Turner Township',
    'Tuscarora Township',
    'Tuscola Township',
    'Tuscola',
    'Tyrone Township',
    'Unadilla Township',
    'Union Township',
    'Utica City',
    'Valley Township',
    'Van Buren Township',
    'Van Buren',
    'Vassar City',
    'Vassar Township',
    'Venice Township',
    'Vergennes Township',
    'Vermontville Township',
    'Vernon Township',
    'Verona Township',
    'Vevay Township',
    'Victor Township',
    'Victory Township',
    'Vienna Township',
    'Village Of Clarkston City',
    'Village Of Douglas City',
    'Volinia Township',
    'Wakefield City',
    'Wakefield Township',
    'Wakeshma Township',
    'Wales Township',
    'Walker City',
    'Walker Township',
    'Walled Lake City',
    'Walton Township',
    'Warner Township',
    'Warren City',
    'Warren Township',
    'Washington Township',
    'Washtenaw',
    'Waterford Township',
    'Waterloo Township',
    'Watersmeet Township',
    'Watertown Township',
    'Watervliet Charter Township',
    'Watervliet City',
    'Watson Township',
    'Waucedah Township',
    'Waverly Township',
    'Wawatam Township',
    'Wayland City',
    'Wayland Township',
    'Wayne City',
    'Wayne Township',
    'Wayne',
    'Weare Township',
    'Webber Township',
    'Webster Township',
    'Weesaw Township',
    'Weldon Township',
    'Wellington Township',
    'Wells Township',
    'West Bloomfield Township',
    'West Branch City',
    'West Branch Township',
    'West Traverse Township',
    'Westland City',
    'Westphalia Township',
    'Wexford Township',
    'Wexford',
    'Wheatfield Township',
    'Wheatland Township',
    'Wheeler Township',
    'White Cloud City',
    'White Lake Township',
    'White Oak Township',
    'White Pigeon Township',
    'White River Township',
    'Whitefish Township',
    'Whiteford Township',
    'Whitehall City',
    'Whitehall Township',
    'Whitewater Township',
    'Whitney Township',
    'Whittemore City',
    'Wilber Township',
    'Wilcox Township',
    'Williams Township',
    'Williamston City',
    'Williamstown Township',
    'Wilmot Township',
    'Wilson Township',
    'Windsor Charter Township',
    'Winfield Township',
    'Winsor Township',
    'Winterfield Township',
    'Wise Township',
    'Wisner Township',
    'Wixom City',
    'Woodbridge Township',
    'Woodhaven City',
    'Woodhull Township',
    'Woodland Township',
    'Woodstock Township',
    'Worth Township',
    'Wright Township',
    'Wyandotte City',
    'Wyoming City',
    'Yale City',
    'Yankee Springs Township',
    'Yates Township',
    'York Township',
    'Ypsilanti City',
    'Ypsilanti Township',
    'Zeeland City',
    'Zeeland Township',
    'Zilwaukee City',
    'Zilwaukee Township',
]
export default locations;