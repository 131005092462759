import { useState, useEffect, useRef } from "react";
import { getPreviewsBySearch } from "../../api.js";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

export default function AdvancedSearch (props) {
  const {
    setPage,
    setNumMatches,
    length,
    setPreviews,
    keyword,
    setKeyword,
    author,
    setAuthor,
    type,
    setType,
    tag,
    setTag,
    minDate,
    setMinDate,
    maxDate,
    setMaxDate,
    link,
    setLink,
  } = props;

  const [searchResults, setSearchResults] = useState(null);
  const focuser = useRef(null);

  useEffect(() => {
    focuser.current && focuser.current.focus();
  }, [focuser, searchResults]);

  const onSearch = (data) => {
    console.log(data);
    const numMatches = data.matches;

    if (numMatches === "0") {
      setSearchResults(
        <Alert
          tabIndex="0"
          variant="danger"
          onClose={() => setSearchResults(null)}
          dismissible
        >
          No submissions match those search terms.
        </Alert>
      );
    } else {
      setNumMatches(numMatches);
      setPage(1);
      setSearchResults(
        <Alert
          tabIndex="0"
          variant="success"
          onClose={() => setSearchResults(null)}
          dismissible
          ref={focuser}
        >
          Found {numMatches} match{numMatches === "1" ? "" : "es"}.
        </Alert>
      );
      setPreviews(Object.values(data.submissionPreviews));
    }
  };

  const search = () => {
    getPreviewsBySearch(
      keyword,
      author,
      type,
      minDate,
      maxDate,
      link,
      "",
      [tag],
      1,
      length
    ).then((res) => {
      onSearch(res);
    });
  };

  const clear = () => {
    setKeyword("");
    setAuthor("");
    setType("");
    setMaxDate("");
    setMaxDate("");
    setSearchResults(null);
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );
    getPreviewsBySearch("", "", "", "", "", "", "", [], 1, length).then(
      (res) => {
        if (res.matches) {
          setNumMatches(res.matches);
        }
        setPreviews(Object.values(res.submissionPreviews));
      }
    );
  };

  const onKeyPress = (event) => {
    if (event.which === 13 /* Enter */) {
      search();
    }
  };

  const handleMinDateChanged = (e) => {
    let val = e.target.value;
    setMinDate(val);
  };
  const handleMaxDateChanged = (e) => {
    let val = e.target.value;
    setMaxDate(val);
  };

  const handleTagChange = (e) => {
    let val = e.target.value.toLowerCase();
    if (val[0] === "#") {
      val = val.substring(1, val.length);
    }
    setTag(val);
  };

  return (
    <div>
      <Form onKeyPress={onKeyPress}>
        <Row>
          <Form.Group as={Col} controlId="searchKeyword">
            <Form.Label>Keyword</Form.Label>
            <Form.Control
              type="text"
              placeholder="Keyword"
              onChange={(e) => setKeyword(e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="searchTag">
            <Form.Label>Tag</Form.Label>
            <Form.Control
              type="text"
              placeholder="Tag"
              onChange={(e) => handleTagChange(e)}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlId="searchAuthor">
            <Form.Label>Author</Form.Label>
            <Form.Control
              type="text"
              placeholder="Full or partial name"
              onChange={(e) => setAuthor(e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} controlID="searchType">
            <Form.Label>Submission Type</Form.Label>
            <Form.Control
              as="select"
              name="type"
              onChange={(e) => setType(e.target.value)}
            >
              <option value="">All</option>
              <option value="written">Written Testimony</option>
              <option value="plan">Any Districting Plan</option>
              <option value="ush">Districting Plan - Congressional</option>
              <option value="senate">Districting Plan - State Senate</option>
              <option value="house">Districting Plan - State House</option>
              <option value="coi">Community of Interest</option>
            </Form.Control>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlID="searchMinDate">
            <Form.Label>Start Date</Form.Label>
            <Form.Control type="date" onChange={handleMinDateChanged} />
          </Form.Group>
          <Form.Group as={Col} controlID="searchMaxDate">
            <Form.Label>End Date</Form.Label>
            <Form.Control type="date" onChange={handleMaxDateChanged} />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlId="searchLink">
            <Form.Label>Districtr Link</Form.Label>
            <Form.Control
              type="text"
              placeholder="https://districtr.org/..."
              onChange={(e) => setLink(e.target.value)}
            />
          </Form.Group>
        </Row>
        {searchResults ? searchResults : ""}
      </Form>
      <div className="text-right">
        <Button variant="primary" onClick={search}>
          Search
        </Button>
        <Button variant="primary" onClick={clear}>
          Clear
        </Button>
      </div>
    </div>
  );
};
