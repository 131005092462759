const tags = [
    'mena',
    'arabamerican',
    'hispanic',
    'latinx',
    'aapi',
    'native american',
    'southasian',
    'chaldean',
    'bangladeshi',
    'bengali',
    'black',
    'historically black',
    'historic neighborhood',
    'veterans',
    'military',
    'activeduty',
    'farming',
    'agriculture',
    'disability community',
    'ada community',
    'state government ',
    'lgbtq+',
    'small business ',
    'retirement community',
    'senior citizens',
    'hospital',
    'airport',
    'community center',
    'industrial district',
    'warehouse district',
    'auto workers',
    'college campus',
    'college students',
    'library',
    'tourism',
    'lakeshore ',
    'hospitality',
    'union',
    'city employees',
    'suburb',
    'urban',
    'island',
    'fishing',
    'yooper',
    'up',
    'upper peninsula',
    'lower peninsula ',
    'thumb',
    'west michigan',
    'se michigan ',
    'southeast michigan',
    'detroit east side',
    'detroit west side',
    'detroit southwest',
    'tribal reservation',
    'tribal group',
    'lake michigan',
    'lake huron',
    'lake superior',
    'lake erie',
    'naacp',
    'vfw',
    'lwv',
    'vnp',
    'lcv',
    'cvp',
    'american legion',
    'chamber of commerce',
    'united way',
    'afl cio',
    'aft',
    'mea',
    'uaw',
    'ffa',
    'rotary club',
    'kiwanis',
    'lions club',
    'shriners',
];

export default tags;
