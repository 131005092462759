import { useState } from 'react'
import {isMobileOnly} from 'react-device-detect';

import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import GalleryCardIcon from '../GalleryCard/GalleryCardIcon.jsx'
import examples from '../../assets/examples.json'
import './Welcome.scss'

export default function Welcome () {
    const [expansion1, setExpansion1] = useState("");
    const coi = examples[2];
    const districts = examples[1];
    const written = examples[0];

    const coiText = (<Row>
                        <p>
                            Respecting <span className="coi">communities of interest</span> is a crucial part of the Michigan redistricting process;
                            the Michigan Constitution states that “<span className="coi">Communities of interest</span> may include, but shall not be limited to,
                            populations that share cultural or historical characteristics or economic interests.”
                            They do not include relationships with political parties, incumbents, or political candidates.
                        </p>
                        <p>
                            When you identify your community for the Commission, they can be better informed about how to draw fair lines that give all Michiganders a voice.
                        </p>
                        <p style={{width: "100%"}}><i>
                        For more information on drawing communities jump to </i> <a href="/#draw" style={{fontWeight: 'bold'}}>How to Draw a Map</a>.

                        </p>
                    </Row>);
    const districtText = (<Row>
                            <p>
                                <span className="plan">Districting plans</span> are maps that
                                define the borders of Congressional or state legislative
                                districts. These district boundaries determine how you and your
                                community are represented at a state and federal level.
                            </p>
                            <p>
                            Districts in Michigan have to follow ranked criteria outlined in the
                            state Constitution, including federal law requiring that all electoral
                            districting plans have very close to equal population per district
                            (One Person, One Vote) and that they provide opportunities for
                            minority groups to elect candidates of choice, where possible
                            (the Voting Rights Act). You can read the full criteria in
                            the <a href="http://www.legislature.mi.gov/(S(glxm1jhp0ygtvb3b0fl1vj4q))/mileg.aspx?page=GetObject&objectname=mcl-Article-IV-6"
                                   style={{fontWeight: 'bold'}}>Constitution</a>.
                            </p>
                            <p><i>For more information on drawing districts, jump to </i> <a href="/#draw" style={{fontWeight: 'bold'}}>How to Draw a Map</a>.</p>
                        </Row>);
    const writtenText = (<Row>
                            <p>
                                Your <span className="written">written testimony</span>, or written
                                public comment,  provides a chance to say anything you want to say to the Commission.
                            </p>
                        </Row>);


    const videoRow = (type) => {
        return (<Row>
        <GalleryCardIcon
            title={type.title || "TITLE"}
            id={'e' + type.id || "id001"}
            type={type.type || "coi"}
            link={type.link || ""}/>
        </Row>)
    };

    return (<div className="m-5">

        <Container fluid>
            <Row className="align-items-start">
            <Col>
                <Row>
                    <p>
Make your voice heard! On Tuesday April 23, 2024, the Michigan Independent Citizens Redistricting Commission reopened its mapping process for the state Senate maps. Use this portal to submit your comments, maps, and feedback to the commission.
                    </p>

                    <p>
                        You can provide your thoughts to the Commission by providing <span className="written">written testimony</span>,
                        by drawing your own <span className="plan">districting plan</span> (partial or whole),
                        or by submitting a <span className="coi">community of interest</span> map.
                    </p>
                </Row>
                {isMobileOnly ? <>
                            <Row>
                                <span className="primary">Intro to Redistricting (5 mins)</span>
                                <iframe
                                    src="https://www.youtube.com/embed/FWfGrEQehUk"
                                    style={{ height: "460px", width: "100%" }}
                                    title="Intro to Redistricting" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen>
                                </iframe>
                            </Row>
                            </> : ""}
            </Col>

            {isMobileOnly ? "" :
                <>
                <Col xs="auto"></Col>
                <Col xs={5}>
                    <span className="primary">Intro to Redistricting (5 mins)</span>
                    <iframe
                        src="https://www.youtube.com/embed/FWfGrEQehUk"
                        style={{ height: "460px", width: "100%" }}
                        title="Intro to Redistricting" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen>
                    </iframe>
                </Col>
                </>
            }

            </Row>
        </Container>
        <div style={{marginTop: "2.5rem"}}>
            <strong>What is...</strong>

            <div className="text-center button-group" >
                <Button variant="written" onClick={() => setExpansion1("written")} ><strong>Written Testimony</strong></Button>
                {' '}{' '}{' '}
                <Button variant="plan" onClick={() => setExpansion1("plan")} ><strong>a Districting Plan</strong></Button>
                {' '}{' '}{' '}
                <Button variant="coi" onClick={() => setExpansion1("coi")} ><strong>a Community of Interest</strong></Button>

            </div>
        </div>
        <div className="expansion">
            {{
                "coi":
                <Container>
                    <Row className="align-items-center">
                        <Col>
                            {coiText}
                            {isMobileOnly ? videoRow(coi): ""}
                        </Col>
                        {isMobileOnly ? "" :
                            <>
                            <Col xs="auto">
                            </Col>
                            <Col xs="auto">
                                {videoRow(coi)}
                            </Col>
                            </>}
                    </Row>
                </Container>
                    ,
                "plan":
                <Container>
                    <Row className="align-items-center">
                        <Col>
                            {districtText}
                            {isMobileOnly ? videoRow(districts): ""}
                        </Col>
                        {isMobileOnly ? "" :
                            <>
                            <Col xs="auto">
                            </Col>
                            <Col xs="auto">
                                {videoRow(districts)}
                            </Col>
                            </>}
                    </Row>
                </Container>,
                "written":
                <Container>
                    <Row className="align-items-center">
                        <Col>
                            {writtenText}
                            {isMobileOnly ? videoRow(written): ""}
                        </Col>
                        {isMobileOnly ? "" :
                            <>
                            <Col xs="auto">
                            </Col>
                            <Col xs="auto">
                                {videoRow(written)}
                            </Col>
                            </>}
                    </Row>
                </Container>
            }[expansion1]}
        </div>
    </div>)
}
