import { useState, useRef } from "react";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import Badge from "react-bootstrap/Badge";
import InputGroup from "react-bootstrap/InputGroup";

import tagwords from "../../assets/tags";
import locations from "../../assets/locations";
import clearText from "../../clearText";

import "./InputTags.scss";

/**
 * Return a de-duped list where X city and X township are collapsed into just X
 * @param {a sorted list of lowercase locations} locations
 */
function filterCityTownship(locations) {
  let a = [];
  let cityLocation = "";
  let locationRoot = "";
  // Only push an entry that doesn't match the last one
  function pushIfNew(s) {
    if (a.length == 0 || a[a.length - 1] != s) {
      a.push(s);
    }
  }
  for (const location of locations) {
    if (location.endsWith(" city")) {
      if (cityLocation) pushIfNew(cityLocation);
      cityLocation = location;
      locationRoot = location.substring(0, location.lastIndexOf(" city"));
    } else {
      if (location == locationRoot + " township") {
        pushIfNew(locationRoot);
      } else {
        if (cityLocation) a.push(cityLocation);
        pushIfNew(location);
      }
      cityLocation = "";
      locationRoot = "";
    }
  }
  if (cityLocation) pushIfNew(cityLocation);
  return a;
}
/**
 * Merge the list of locations and tags.  Collapse X City and X Township.  Remove duplicates.
 */
const options = filterCityTownship(
  tagwords
    .concat(locations)
    .map((n) => n.toLowerCase())
    .sort()
);

export default function InputTags (props) {
  const { tags, setTags, controlId } = props;
  const [inputVal, setInputVal] = useState("");
  const [completions, setCompletions] = useState([]);
  const [active, setActive] = useState(-1);
  const inputEl = useRef(null);

  const addTag = (t) => {
    if (t && !tags.includes(t)) setTags((tags) => [...tags, t]);
    setInputVal("");
    setCompletions([]);
  };

  const handleKeyDown = (e) => {
    let val = e.target.value.toLowerCase();
    if (e.key === "Enter") {
      if (active > -1) {
        addTag(completions[active]);
      } else if (val) {
        if (val[0] === "#") {
          val = val.substring(1, val.length);
        }
        addTag(val);
        e.preventDefault();
      }
    } else if (e.key === "Backspace" && !val) {
      deleteTag(tags[tags.length - 1]);
    } else if (e.key === "ArrowUp") {
      setActive((active) => Math.max(active - 1, -1));
    } else if (e.key === "ArrowDown") {
      setActive((active) => Math.min(active + 1, completions.length));
    }
  };
  const handleChange = (e) => {
    let val = e.target.value.toLowerCase();
    setInputVal(val);
    if (val[0] === "#") {
      val = val.substring(1, val.length);
    }
    setActive(-1);
    setCompletions(
      val.length
        ? options.filter((option) => option.startsWith(val)).slice(0, 5)
        : []
    );
  };

  const handleBlur = () => {
    setCompletions([]);
  };

  const handleClick = (c) => {
    addTag(c);
    inputEl.current.focus();
  };

  const deleteTag = (t) => {
    setTags((tags) => tags.filter((tag) => tag !== t));
  };

  return (
    <div>
      <Form.Group controlId={controlId}>
        <Form.Label>Enter tags here:</Form.Label> <br />
        <InputGroup>
            <InputGroup.Text>#</InputGroup.Text>

          <Form.Control
            value={inputVal}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            className="testimony"
            onChange={handleChange}
            ref={inputEl}
            placeholder="start typing to see tag suggestions"
            maxLength={28}
          />
        </InputGroup>
      </Form.Group>

      <ListGroup>
        {completions.map((c, i) => (
          <ListGroup.Item
            className="text-muted"
            key={i}
            action
            active={completions[active] === c}
            onMouseDown={() => handleClick(c)}
          >
            #{c}
          </ListGroup.Item>
        ))}
      </ListGroup>

      {tags.map((t, i) => (
        <>
          <Badge variant="primary" key={i} onClick={() => deleteTag(t)}>
            #{clearText(t)} &nbsp; <Badge className="delete">X</Badge>
          </Badge>{" "}
        </>
      ))}
    </div>
  );
};
