// import Submissions from "../Submissions/Submissions.jsx";
import GalleryCardIcon from "../GalleryCard/GalleryCardIcon.jsx";
import CardGroup from "react-bootstrap/CardGroup";
import DraftForm from "../DraftPlans/DraftForm.jsx";

// import AdvancedSearch from "../Search/AdvancedSearch.jsx";
import Anchor from "../Anchor/Anchor.jsx";

import draftPlans from "../../assets/drafts.json";

export default function DraftPlanPage ({ refresh, triggerRefresh }) {
  return (
    <div className="details">
      <Anchor>MICRC Draft Plans</Anchor>
      <div className="m-5 gallery-wrap">
        <p>
          <strong>Congressional</strong>
        </p>
        <CardGroup className="gallery icons">
          {draftPlans
            .filter((d) => d.districttype === "ush")
            .map((plan, i) => (
              <li key={i} style={{ listStyle: "none" }}>
                <GalleryCardIcon
                  title={plan.title}
                  id={plan.id}
                  type={plan.type}
                  link={plan.link}
                  isDraft={true}
                />
              </li>
            ))}
        </CardGroup>
        <p>
          <strong>State Senate</strong>
        </p>
        <CardGroup className="gallery icons">
          {draftPlans
            .filter((d) => d.districttype === "senate")
            .map((plan, i) => (
              <li key={i} style={{ listStyle: "none" }}>
                <GalleryCardIcon
                  title={plan.title}
                  id={plan.id}
                  type={plan.type}
                  link={plan.link}
                  isDraft={true}
                />
              </li>
            ))}
        </CardGroup>
        <p>
          <strong>State House</strong>
        </p>
        <CardGroup className="gallery icons">
          {draftPlans
            .filter((d) => d.districttype === "house")
            .map((plan, i) => (
              <li key={i} style={{ listStyle: "none" }}>
                <GalleryCardIcon
                  title={plan.title}
                  id={plan.id}
                  type={plan.type}
                  link={plan.link}
                  isDraft={true}
                />
              </li>
            ))}
        </CardGroup>
      </div>
      <Anchor id="form">Comment on a Proposed Plan</Anchor>
      Please use the form below to submit a public comment.
      <DraftForm
        refresh={refresh}
        triggerRefresh={triggerRefresh}
        formType={"drafts"}
      />
    </div>
  );
};
