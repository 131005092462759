import { useState, useEffect } from "react";
import { getPreviewsBySearch } from "../../api.js";
import GalleryCardIcon from "../GalleryCard/GalleryCardIcon";

export default function Modifications ({ draft }) {
  const [previews, setPreviews] = useState([]);
  const [numMatches, setNumMatches] = useState(0);

  useEffect(() => {
    getPreviewsBySearch("", "", "", "", "", "", draft).then((res) => {
      setNumMatches(res.matches || 0);
      setPreviews(Object.values(res.submissionPreviews));
    });
    console.log(numMatches);
    console.log(draft);
  }, []);

  return (
    <div>
      {previews.map((preview, i) => (
        <div key={i} className="modification">
          <GalleryCardIcon
            id={"p" + preview.id}
            type={preview.type}
            link={preview.link}
            title={preview.title}
          />
        </div>
      ))}
      {previews.length === 0
        ? "No modification plans have been submitted."
        : ""}
    </div>
  );
};
