import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Comments from "./Comments/Comments.jsx";
import Anchor from "./Anchor/Anchor.jsx";

import { getPreviewsBySearch, getPreview } from "../api.js";
import clearText from "../clearText";

export default class PrintPreview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      previews: [],
      page: window.location.href.includes("page=")
        ? Number(window.location.href.split("page=")[1].split("&")[0])
        : 1,
    };
  }

  componentDidMount() {
    const { page } = this.state;
    getPreviewsBySearch("", "", "", "", "", "", "", [], page, 12, true).then(
      (res) => {
        const prev = Object.values(res.submissionPreviews);
        this.setState({ previews: prev });

        prev.forEach((s, i) => {
          if (s.type === "plan" || s.type === "coi") {
            let did = s.link.split("/").slice(-1)[0];
            did = did.split("?")[0].split("&")[0];
            if (did.length) {
              getPreview.then((api) => {
                if (api.screenshot) {
                  const mod = this.state.previews.map((current, idx) =>
                    idx === i ? { ...current, img: api.screenshot } : current
                  );
                  console.log(mod);
                  this.setState({ previews: mod });
                }
              });
            }
          }
        });
      }
    );
  }

  render() {
    const { previews, page } = this.state;

    return (
      <div className="printable">
        <div className="m-5">
          <br />
          <br />
          <div className="no-print">
            {page > 1 ? (
              <a href={"/print?page=" + (page - 1)}>&larr; Next</a>
            ) : null}
            <a style={{ float: "right" }} href={"/print?page=" + (page + 1)}>
              Previous &rarr;
            </a>
          </div>
          {previews.map((plan, p) => {
            return (
              <div key={p} className="details print-page">
                <Row>
                  <Col>
                    <Row>
                      <h4>
                        {clearText(plan.title.substring(0, 1000))} (
                        {plan.type ? plan.type[0] + plan.id : ""})
                      </h4>
                      <Anchor size="1.2">Basic Info</Anchor>
                    </Row>
                    <Row>
                      <div>
                        Submitter:{" "}
                        {clearText(plan.first) + " " + clearText(plan.last)}{" "}
                        <br />
                        Location: {clearText(plan.city)} <br />
                        Submitted:{" "}
                        {plan.datetime
                          ? new Date(plan.datetime).toLocaleDateString()
                          : ""}{" "}
                        <br />
                        Type: {clearText(plan.type)} <br />
                        {plan.type === "plan" && plan.districttype ? (
                          <>
                            {clearText(
                              plan.districttype.replace("Reapportioned ", "")
                            )}
                            <br />
                          </>
                        ) : null}
                      </div>
                    </Row>
                    {plan.tags && plan.tags.length ? (
                      <>
                        <Row>
                          <Anchor size="1.2">Tags</Anchor>
                        </Row>
                        <Row>
                          <div className="tags-expo">
                            {(plan.tags || []).map((tag, i) => (
                              <a
                                className="post-tag"
                                href={"/?tag=" + clearText(tag) + "#gallery"}
                                key={i}
                              >
                                #{clearText(tag)}&nbsp;&nbsp;
                              </a>
                            ))}
                          </div>
                        </Row>
                      </>
                    ) : (
                      ""
                    )}
                    {plan.type === "plan" || plan.type === "coi" ? (
                      <>
                        <Row>
                          <Anchor size="1.2">More Info</Anchor>
                        </Row>
                        <Row>
                          <Col>
                            {clearText(plan.text)
                              .substring(0, 10000)
                              .split(/\n+/)
                              .map((section, i) => (
                                <div key={i}>{section}</div>
                              ))}
                          </Col>
                        </Row>
                      </>
                    ) : (
                      ""
                    )}
                    {plan.comments && plan.comments.length ? (
                      <>
                        <Row>
                          <Anchor size="1.2">Comments & Feedback</Anchor>
                        </Row>
                        <Row>
                          <Comments
                            comments={plan.comments || []}
                            commenttags={plan.commenttags || []}
                            id={plan.type ? plan.type[0] + plan.id : ""}
                            isExample={false}
                          />
                        </Row>
                      </>
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col xs={1}></Col>
                  {plan.img ? (
                    <Col>
                      <Row>
                        <img
                          id="districtr-box"
                          style={{ width: "100%", margin: "0.5rem 0" }}
                          src={plan.img}
                        />
                      </Row>
                    </Col>
                  ) : (
                    <Col>
                      {plan.type === "file" ? (
                        <Row>
                          <Anchor size="1.2">Sent File to Commission</Anchor>
                        </Row>
                      ) : null}
                      {plan.link && plan.type !== "file" ? (
                        <>
                          <Row>
                            <Anchor size="1.2">External Link</Anchor>
                          </Row>
                          <Row>
                            <p>
                              Link: <a href={plan.link}>{plan.link}</a>
                            </p>
                          </Row>
                          <Row>
                            <div className="p-2 text-muted">
                              (This is a user submitted link. Open external
                              links with caution)
                            </div>
                          </Row>
                        </>
                      ) : (
                        ""
                      )}
                      <Row>
                        <Anchor size="1.2">Written Testimony</Anchor>
                      </Row>
                      <Row>
                        <Col>
                          {clearText(plan.text)
                            .split(/\n+/)
                            .map((section, i) => (
                              <div key={i}>{section}</div>
                            ))}
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
