import clearText from "../../clearText";

export default function Comments (props) {
  const { comments } = props;

  return (
    <div>
      {comments.map((c, i) => (
        <div key={i} className="comment">
          {new Date(c.datetime).toLocaleDateString()} -{" "}
          <strong>{clearText(c.first + " " + c.last)}</strong>
          {c.city ? <> ({clearText(c.city)})</> : ""}: {clearText(c.text)}{" "}
          {(c.tags || []).map((tag, i) => (
            <a
              className="post-tag"
              href={"/?tag=" + clearText(tag) + "#gallery"}
              key={i}
            >
              #{clearText(tag)}&nbsp;&nbsp;
            </a>
          ))}
        </div>
      ))}
      <div>
        {comments.length === 0 ? "No comments have been submitted." : ""}
      </div>
    </div>
  );
};
