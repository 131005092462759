import React from "react";
import { isMobileOnly } from "react-device-detect";

// import { useHistory } from "react-router-dom"

import DraftComments from "../DraftPlans/DraftComments.jsx";
import Anchor from "../Anchor/Anchor.jsx";

import "../DetailPage/DetailPage.scss";

import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Modifications from "../DraftPlans/Modifications.jsx";

import { getDetails, getPreview, movePage } from "../../api.js";
import clearText from "../../clearText";
import examples from "../../assets/examples.json";
import drafts from "../../assets/drafts.json";

export default class DetailPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      image: "/thumbmi.png",
      plan: {},
      id: window.location.href.split("/").slice(-1)[0].split("?")[0],
      isExample: false,
      iframe: "about:blank",
      districttype: null,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    if (this.state.id[0] === "e") {
      const p = examples[this.state.id.substring(1)];
      this.setState({ plan: p });
      this.setState({ isExample: true });
      if (p.type === "plan" || p.type === "draft" || p.type === "coi") {
        let did = p.link.split("/").slice(-1)[0];
        did = did.split("?")[0].split("&")[0];
        if (did.length) {
          if (
            window.screen &&
            window.screen.width &&
            window.screen.width < 600
          ) {
            return;
          }
          getPreview(this.state.id.substring(1)).then((api) => {
            if (api.screenshot) {
              this.setState({
                image: api.screenshot,
                iframe: "https://districtr.org/embedded?id=" + did,
              });
            } else {
              this.setState({
                iframe: "https://districtr.org/embedded?id=" + did,
              });
            }
          }).catch(() => {
            this.setState({
              iframe: "https://districtr.org/embedded?id=" + did,
            });
          });
        }
      }
    } else if (this.state.id[0] === "d") {
      const p = drafts[this.state.id.substring(1) - 1];
      this.setState({ plan: p });
      this.setState({ isDraft: true });
      if (p.type === "plan" || p.type === "draft" || p.type === "coi") {
        let did = p.link.split("/").slice(-1)[0];
        did = did.split("?")[0].split("&")[0];
        if (did.length) {
          if (
            window.screen &&
            window.screen.width &&
            window.screen.width < 600
          ) {
            return;
          }
          getPreview(this.state.id.substring(1)).then((api) => {
            if (api.screenshot) {
              this.setState({
                image: api.screenshot,
                iframe: "https://districtr.org/embedded?id=" + did,
              });
            } else {
              this.setState({
                iframe: "https://districtr.org/embedded?id=" + did,
              });
            }
          }).catch(() => {
            this.setState({
              iframe: "https://districtr.org/embedded?id=" + did,
            });
          });
        }
      }
    } else {
      getDetails(this.state.id.substring(1))
        .then((res) => {
          let plan = res.submissionDetails;
          let stateful = {};
          stateful.plan = plan;

          if (
            plan.type === "plan" ||
            plan.type === "draft" ||
            plan.type === "coi"
          ) {
            let did = plan.link.split("/").slice(-1)[0];
            did = did.split("?")[0].split("&")[0];
            if (did.length && !isNaN(1 * did)) {
              if (!isMobileOnly) {
                stateful.iframe = "https://districtr.org/embedded?id=" + did;
              }

              getPreview(did).then((api) => {
                this.setState({
                  image: api.screenshot || null,
                  districttype:
                    api.plan && api.plan.problem && api.plan.problem.pluralNoun
                      ? api.plan.problem.pluralNoun
                      : null,
                });
              });
            }
          }
          this.setState(stateful);
        })
        .catch((err) => {
          console.error("There was an error!", err);
          // if (err.response.status === 404) {
          //   history.push("/404");
          // }
        });
    }

    // if (image === null) {
    //   this.setState({ image: '/thumbmi.png' });
    // }
    this.setState({ loaded: true });
  }

  loadPrevious(e) {
    movePage(this.state.id.substring(1), -1).then((res) => {
      if (res && res.nextid && res.nextid.length) {
        window.location.href = "/submission/x" + res.nextid[0].id;
      } else {
        window.alert("No next submission");
        e.target.style.display = "none";
      }
    });
  }
  loadNext(e) {
    movePage(this.state.id.substring(1), 1).then((res) => {
      if (res && res.nextid && res.nextid.length) {
        window.location.href = "/submission/x" + res.nextid[0].id;
      } else {
        window.alert("No next submission");
        e.target.style.display = "none";
      }
    });
  }

  render() {
    const { loaded, plan, isExample, districttype } = this.state;

    // get source of link (Representable / DRA)
    let link;
    const allowLinks = [
      "www.representable.org",
      "representable.org",
      "www.davesredistricting.org",
      "davesredistricting.org",
    ];
    if (plan && plan.link) {
      try {
        link = new window.URL(plan.link);
      } catch (e) {
        link = plan.link;
      }
    }

    return (
      <div className="details">
        <Container fluid>
          {loaded ? (
            ""
          ) : (
            <Row className="justify-content-md-center">
              <Alert variant="info">
                Loading... <Spinner animation="border" role="status" />
              </Alert>
            </Row>
          )}
          {plan.type !== "draft" && (
            <div>
              <button
                className="btn page-btn"
                onClick={this.loadNext.bind(this)}
              >
                &larr; Next
              </button>
              <button
                className="btn page-btn"
                style={{ float: "right" }}
                onClick={this.loadPrevious.bind(this)}
              >
                Previous &rarr;
              </button>
            </div>
          )}

          <Col>
            <Row>
              <h1>{clearText(plan.title)}</h1>
            </Row>
            <Row>
              <Col>
                <Row>
                  <Anchor size="1.2">Basic Info</Anchor>
                </Row>
                <Row>
                  <div>
                    Submitter:{" "}
                    {clearText(plan.first) + " " + clearText(plan.last)} <br />
                    Location: {clearText(plan.city)} <br />
                    Submitted:{" "}
                    {plan.datetime
                      ? new Date(plan.datetime).toLocaleDateString()
                      : ""}{" "}
                    <br />
                    Type: {clearText(plan.type)} <br />
                    {(plan.type === "plan" || plan.type === "draft") &&
                    districttype ? (
                      <>
                        {clearText(districttype.replace("Reapportioned ", ""))}
                        <br />
                      </>
                    ) : null}
                    ID: {plan.id} <br />
                  </div>
                </Row>
              </Col>
              <Col xs={1}></Col>
              {plan.type === "plan" ||
              plan.type === "draft" ||
              plan.type === "coi" ? (
                <Col>
                  <Row>
                    <Anchor size="1.2">Map</Anchor>
                    <p>
                      You can pan and zoom in the embedded map with your mouse
                      or the +/- buttons.
                    </p>
                  </Row>
                  <Row>
                    <iframe
                      title="districtr preview"
                      id="districtr-box"
                      style={{ height: 600, width: "100%", margin: "2rem 0" }}
                      src={this.state.iframe}
                      frameBorder="0"
                    ></iframe>
                  </Row>
                  <Row className="align-items-center">
                    <Button
                      href={
                        (plan.link.includes("portal")
                          ? plan.link
                          : plan.link + "?portal") + `&draft=${plan.id}`
                      }
                      target="_blank"
                      id="districtrButton"
                    >
                      View in Districtr
                    </Button>
                    <div>
                      (To see statistics on this map, or to modify it yourself.)
                    </div>
                  </Row>
                </Col>
              ) : (
                <Col>
                  {plan.type === "file" ? (
                    <Row>
                      <Anchor size="1.2">Sent File to Commission</Anchor>
                    </Row>
                  ) : null}
                  {plan.link && plan.type !== "file" ? (
                    <>
                      <Row>
                        <Anchor size="1.2">External Link</Anchor>
                      </Row>
                      <Row>
                        <p>
                          Link:{" "}
                          {allowLinks.includes(link.host || link.hostname) ? (
                            <a href={plan.link}>{plan.link}</a>
                          ) : (
                            clearText(plan.link)
                          )}
                        </p>
                      </Row>
                      <Row>
                        <div className="p-2 text-muted">
                          {allowLinks.includes(link.host || link.hostname)
                            ? ""
                            : "(This is a user submitted link. Open external links with caution)"}
                        </div>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}
                  <Row>
                    <Anchor size="1.2">Written Testimony</Anchor>
                  </Row>
                  <Row>
                    <Col>
                      {clearText(plan.text)
                        .split(/\n+/)
                        .map((section, i) => (
                          <div key={i}>{section}</div>
                        ))}
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
            <hr />
            <Row className="justify-content-md-center">
              {isExample ? null : (
                <a
                  className="btn btn-primary"
                  href={`/plans?commentid=${plan.id}#form`}
                >
                  Proceed to Submit a Comment
                </a>
              )}
            </Row>
            <Row>
              <Col>
                <Row>
                  <Anchor size="1.2">Comments & Feedback</Anchor>
                </Row>
                <Row>
                  <DraftComments id={plan.id} />{" "}
                </Row>
              </Col>
              {plan.type === "draft" && (
                <>
                  <Col xs={1}></Col>
                  <Col>
                    <Row>
                      <Anchor size="1.2">Modifications</Anchor>
                    </Row>
                    <Row>
                      <Modifications draft={plan.id} /> {this.id}
                    </Row>
                  </Col>
                </>
              )}
            </Row>
          </Col>
        </Container>
      </div>
    );
  }
}
