import Form from "react-bootstrap/Form";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Alert from "react-bootstrap/Alert";
import TabContent from "react-bootstrap/TabContent";
import { useState, useEffect, useRef } from "react";
import { getPreview } from "../../api";
import { debounce } from "lodash";

export default function DraftFormTabs (props) {
  const {
    register,
    prefillid,
    setPrefillid,
    type,
    setType,
    districtrContent,
    setDistrictrContent,
  } = props;
  const [districtrID, setDistrictrID] = useState(
    prefillid &&
      (window.location.href.includes("planid=") ||
        window.location.href.includes("coiid="))
      ? new window.URL(prefillid).pathname.split("/").slice(-1)[0]
      : null
  );
  const [invalidDistrictrLink, setInvalidDistrictrLink] = useState(false);

  const focuser = useRef(null);
  useEffect(() => {
    focuser.current && focuser.current.focus();
    if (districtrID) {
      verify(districtrID);
    }
  }, [focuser, districtrID]);

  const DistrictrCheck = (props) => {
    const { districtrID, districtrContent, name, pluralName } = props;
    if (districtrContent && districtrContent.error) {
      return (
        <Alert
          tabIndex="0"
          variant="danger"
          dismissible
          onClose={() => setDistrictrContent("")}
          ref={focuser}
        >
          {districtrContent.error}
        </Alert>
      );
    }
    if (districtrID && districtrContent) {
      return (
        <div>
          Found&nbsp;
          <img src={districtrContent.img} width="100" />
          <Form.Control
            {...register('districttype')}
            aria-label="Hidden district type code"
            defaultValue={districtrContent.districttype}
            style={{ display: "none" }}
          />
          {districtrContent.districts}{" "}
          {districtrContent.districts === 1 ? name : pluralName}
          {districtrContent.landmarks ? (
            <>
              {" "}
              and {districtrContent.landmarks}{" "}
              {districtrContent.landmarks === 1 ? "place" : "places"}
            </>
          ) : null}
        </div>
      );
    }
    return null;
  };
  const verify = (id) => {
    getPreview(id).then((p) => {
      let districtSet = new Set(),
        landmarkCount = 0;
      if (p.plan) {
        (Object.values(p.plan.assignment || {}) || []).forEach((val) => {
          if (val && val.length) {
            val.filter((v) => v || v === 0).forEach((v) => districtSet.add(v));
          } else if (val || val === 0) {
            districtSet.add(val);
          }
        });
      } else {
        props.setBlockSubmit(true);
        return setDistrictrContent({
          error: "Could not find this Districtr id",
        });
      }
      if (!p.plan || !p.plan.place || p.plan.place.id !== "michigan") {
        props.setBlockSubmit(true);
        return setDistrictrContent({
          error: "Did not find a Michigan plan at this URL",
        });
      }
      props.setBlockSubmit(false);
      if (
        p.plan &&
        p.plan.place &&
        p.plan.place.landmarks &&
        p.plan.place.landmarks.data &&
        p.plan.place.landmarks.data.features
      ) {
        landmarkCount = p.plan.place.landmarks.data.features.length;
      }
      if (!p.screenshot && id.length < 14) {
        // reload in 1/3 second
        setTimeout(() => {
          setDistrictrID(id + " ");
        }, 300);
      }
      setDistrictrContent({
        img: p.screenshot || "",
        districts: districtSet.size,
        landmarks: landmarkCount,
        districttype: {
          "Reapportioned Congressional Districts": "ush",
          "Congressional Districts": "ush",
          "State Senate Districts": "senate",
          "State House Districts": "house",
        }[p.plan.problem.pluralNoun],
      });
    });
  };

  const handleSelect = (t) => {
    setType(t);
    setPrefillid("");
    setDistrictrContent(null);
    props.setBlockSubmit(false);
  };

  const isValidDistrictrLink = debounce((url, type) => {
    let disid,
      validity = false;
    try {
      const myurl = new window.URL(url.toLowerCase()),
        host = myurl.host || myurl.hostname;
      disid = myurl.pathname.split("/").slice(-1)[0];
      validity =
        !isNaN(1 * disid) &&
        disid * 1 >= 1000 &&
        ["districtr.org", "www.districtr.org"].includes(host);
      if (!validity) {
        setDistrictrContent({ error: "Not a valid Districtr URL" });
      } else if (
        type &&
        !myurl.pathname.includes("/" + type + "/") &&
        !myurl.pathname.includes("/edit/")
      ) {
        if (type === "plan") {
          setDistrictrContent({
            error: "Select the 'Community' tab for this URL",
          });
        } else {
          setDistrictrContent({
            error: "Select the 'Districting Plan' tab for this URL",
          });
        }
        validity = false;
      }
    } catch (e) {
      validity = false;
      setDistrictrContent({ error: "Not a valid Districtr URL" });
    }
    if (
      validity &&
      districtrContent &&
      (districtrContent.error || "").includes("valid")
    ) {
      setDistrictrContent({ error: null });
    }
    setInvalidDistrictrLink(!validity);
    props.setBlockSubmit(!validity);
    setDistrictrID(validity ? disid : null);
  }, 500);

  return (
    <>
      <Tabs
        transition={false}
        activeKey={type}
        onSelect={handleSelect}
        unmountOnExit
      >
        <Tab eventKey="comment" title="Add a Comment">
          <div className="m-4">
            Use this space to add a comment or to add tags to proposed
            submissions. Get the plan ID from a draft submission (example: d16)
            and add your content in this form. Alternatively, you can start in
            the gallery with a plan you’d like to comment on and proceed here
            with the plan ID pre-filled.
          </div>
          <TabContent>
            <div>
              <Form.Group controlId="sub-commentid">
                <Form.Label>
                  <strong>ID of the submission you wish to comment on*</strong>{" "}
                  (example: d1, d10)
                </Form.Label>
                <Form.Control
                  {...register('draft', { required: true })}
                  defaultValue={new URLSearchParams(window.location.search).get(
                    "commentid"
                  )}
                  className="testimony"
                  placeholder="Plan ID to comment on"
                  maxLength="10"
                  style={{ width: "30%" }}
                />
              </Form.Group>
              <Form.Group controlId="sub-commenttext">
                <Form.Label>
                  <strong>Comment*</strong>
                </Form.Label>
                <Form.Control
                  {...register('text', { required: true })}
                  as="textarea"
                  rows={3}
                  className="testimony"
                  placeholder="Comment text"
                  maxLength="10000"
                  onKeyPress={(e) =>
                    e.key === "Enter" ? e.stopPropagation() : null
                  }
                />
              </Form.Group>
            </div>
          </TabContent>
        </Tab>
        <Tab eventKey="plan" title="Modify Proposed Plan">
          <div className="m-4">
            Include a Districtr link here to a modified districting plan for
            Michigan.
          </div>
          <TabContent>
            <Form.Group controlId="sub-district">
              <Form.Label>
                <strong>ID of the draft plan you wish to modify*</strong>
              </Form.Label>

              <Form.Control
                {...register('draft', { required: true })}
                defaultValue={new URLSearchParams(window.location.search).get(
                  "draftid"
                )}
                placeholder="Plan ID to modify"
                className="testimony"
                maxLength="300"
                style={{ width: "30%" }}
              />
            </Form.Group>

            <Form.Group controlId="sub-district">
              <Form.Label>
                <strong>Districtr Plan URL*</strong>
              </Form.Label>

              <Form.Control
                {...register('link', { required: true })}
                defaultValue={prefillid}
                placeholder="A Districtr districting plan url"
                onChange={(e) => isValidDistrictrLink(e.target.value, "plan")}
                className="testimony"
                isInvalid={invalidDistrictrLink}
                maxLength="300"
                style={{ width: "30%" }}
              />
            </Form.Group>

            <DistrictrCheck
              districtrID={districtrID}
              districtrContent={districtrContent}
              name="district"
              pluralName="districts"
            />

            <Form.Group controlId="else">
              <Form.Label>
                <strong>Tell us about your modifications*</strong>
              </Form.Label>

              <Form.Control
                {...register('text')}
                as="textarea"
                className="testimony"
                placeholder="How did you change the draft plan? What is notable about your
                modification?"
                maxLength="10000"
                onKeyPress={(e) =>
                  e.key === "Enter" ? e.stopPropagation() : null
                }
              />
            </Form.Group>
          </TabContent>
        </Tab>
      </Tabs>
    </>
  );
};
