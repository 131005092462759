import Pagination from 'react-bootstrap/Pagination';



const GalleryPaging = props => {
    const {page, setPage, length, numMatches} = props;

    if (numMatches === 0) {
        return(<></>);
    }

    const numPages = Math.ceil(numMatches/length);
    
    const changePage = (p) => {
        setPage(Math.min(Math.max(p, 1), numPages))
    }

    let pages = [page-1, page, page+1];
    if (page === 1) {
        pages.shift()
    }
    if (page === numPages) {
        pages.pop()
    }

    return (
        <Pagination className="justify-content-center">
            <Pagination.First onClick={() => changePage(1)}/>
            <Pagination.Prev onClick={() => changePage(page - 1)}/>
            {pages[0] === 1 ? "" : <Pagination.Ellipsis disabled/> }
            {pages.map(num => 
                <Pagination.Item key={num} active={num === page} onClick={() => changePage(num)}>
                    {num}
                </Pagination.Item>
            )}
            {pages[pages.length - 1] === numPages ? "" : <Pagination.Ellipsis disabled/>}
            <Pagination.Next onClick={() => changePage(page + 1)}/>
            <Pagination.Last onClick={() => changePage(numPages)}/>
        </Pagination>
    )
};

export default GalleryPaging;