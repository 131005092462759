import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { useState } from "react";
import { isMobileOnly } from "react-device-detect";

import "./App.scss";

import Welcome from "../Welcome/Welcome.jsx";
import { FormParent } from "../FormParent/FormParent.jsx";
import Gallery from "../Gallery/Gallery.jsx";
// import ExampleGallery from '../Gallery/ExampleGallery.jsx'
import DetailPage from "../DetailPage/DetailPage.jsx";
import DraftDetails from "../DraftPlans/DraftDetails.jsx";
import DraftPlansPage from "../DraftPlans/DraftPlansPage.jsx";
import SearchPage from "../Search/SearchPage.jsx";
import PrintPreview from "../PrintPreview";
import Anchor from "../Anchor/Anchor.jsx";

import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function App () {
  const [filterTags, setFilterTags] = useState(
    window.location.href.includes("tag=")
      ? [
          window.location.href
            .split("tag=")[1]
            .split("#")[0]
            .replace(/%20/g, " "),
        ]
      : []
  );
  const [refresh, triggerRefresh] = useState(false);

  const communityVideo = (
      <Row className={isMobileOnly ? "" : "video"}>
        <span className="plan">Drawing Districts (5 mins)</span>
        <iframe
          src="https://www.youtube.com/embed/jPEbB72UgeI"
          style={
            isMobileOnly
              ? { width: "100%", height: "100%" }
              : {
                  height: "300px",
                }
          }
          title="Drawing Districts"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Row>
  );
  const districtsVideo = (
      <Row className={isMobileOnly ? "" : "video"}>
        <span className="coi">Drawing Communities (5 mins)</span>
        <iframe
          src="https://www.youtube.com/embed/rkNphgv6J_0"
          style={
            isMobileOnly
              ? { position: "relative", width: "100%", height: "100%" }
              : {
                  height: "300px",
                }
          }
          title="Drawing Communities"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Row>
  );

  return (
    <Router>
      <div className="app m-5">
        <Navbar fixed="top" bg="primary" variant="dark" expand="lg">
          <Navbar.Brand href="/" style={{ padding: 3 }}>
            MI Redistricting Public Comment Portal
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link href="/#welcome">Welcome</Nav.Link>
              {/* <Nav.Link href="/#examples">Examples</Nav.Link> */}
              <Nav.Link href="/#submit">Submit Comment</Nav.Link>
              <Nav.Link href="/#draw">How to Map</Nav.Link>
              <Nav.Link href="/#gallery">Gallery</Nav.Link>
              <Nav.Link href="/search">Search</Nav.Link>
              <Nav.Link href="/plans">Draft Plans</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <div className="">
          <Routes>
            <Route exact path="/submission/:id" element={
              <DetailPage />
            }/>
            <Route exact path="/404" element={
              <div className="text-center">404 not found</div>
            }/>
            <Route exact path="/search" element={
              <SearchPage />
            }/>
            <Route exact path="/submission" element={
              <h1>Form page</h1>
            }/>
            <Route exact path="/print" element={
              <PrintPreview />
            }/>
            <Route exact path="/plans" element={
              <DraftPlansPage />
            }/>
            <Route exact path="/plans/:draft" element={
              <DraftDetails />
            }/>
            <Route path="/" element={
              <>
              <div className="jump" id="welcome"></div>
              <Anchor>Welcome</Anchor>
              <Welcome />

              <div className="jump" id="submit"></div>
              <Anchor>Submit Comment</Anchor>
              <div className="m-5">
                <p>
                  Please use the form below to submit public comment to the
                  Commission.
                </p>
                <p>
                  Members of the public are invited to address the Commission
                  live at meetings and public hearings. You can find detailed
                  information about these in-person and virtual forums at{" "}
                  <a
                    href="https://Michigan.gov/MICRC"
                    style={{ fontWeight: "bold" }}
                  >
                    Michigan.gov/MICRC
                  </a>
                  . If the submission portal below doesn’t work for you, you can
                  also provide your written public comment to the Commission by
                  mailing it to them at: P.O. Box 30318, Lansing MI 48909.
                </p>
                <p>
                  The MICRC is providing this website as a public service to
                  engage residents and receive public comment regarding
                  redistricting matters including communities of interest and
                  proposed maps. Please note any obscene or vulgar or
                  threatening material will be removed.
                </p>
                <p>
                  The opinions or information contained within the public
                  comment portal are the creation of outside parties and do not
                  necessarily reflect the opinions of the MICRC. Therefore, the
                  MICRC is not responsible for, and expressly disclaims all
                  liability for, damages of any kind arising out of use,
                  reference to, or reliance on any information contained within
                  this website. No warranty expressed or implied is made
                  regarding accuracy, adequacy, completeness, legality,
                  reliability or usefulness of any information posted in the
                  public comment portal. This disclaimer applies to both
                  isolated and aggregate uses of the information.
                </p>
                <p>
                  This site contains links to several other websites. The MICRC
                  does not endorse, ensure the accuracy of, or vouch for the
                  safety and privacy of any linked sites nor does it assume any
                  responsibility for the content found on those sites. The links
                  provided on this site are solely intended to provide
                  additional information. It is the responsibility of the web
                  user to evaluate the content and usefulness of information
                  obtained from this or other linked sites.
                </p>
              </div>
              <FormParent refresh={refresh} triggerRefresh={triggerRefresh} />

              <div className="jump" id="draw"></div>
              <Anchor>How to Draw a Map</Anchor>
              <div className="m-5">
                <p>
                  You can use{" "}
                  <a className="link" href="https://districtr.org">
                    Districtr.org
                  </a>{" "}
                  to draw your <span className="plan">plan</span> or your{" "}
                  <span className="coi">community</span>. Districtr is free,
                  open-source software designed just for public input in the
                  redistricting process. When you&apos;ve drawn your map, click
                  the &quot;Save&quot; button and you’ll find a submission link
                  that will take you back to this form with your mapping data
                  pre-filled.
                </p>
                <p>
                  Maps made on other software, such as Representable or Dave’s
                  Redistricting App, are welcome as well! Just use the “Other
                  link” option in the submission form.
                </p>
                <p>
                  <span>Need help getting started?</span> Here are some video
                  tutorials.
                </p>
                <Container fluid>
                  {isMobileOnly ? (
                    <>
                      {districtsVideo}
                      {communityVideo}
                    </>
                  ) : (
                    <Row className="align-items-center">
                      <Col>{communityVideo}</Col>
                      <Col xs="auto"></Col>
                      <Col>{districtsVideo}</Col>
                    </Row>
                  )}
                </Container>
                <div style={{ marginTop: "3.5rem" }}>
                  <p>
                    It takes some time to figure out
                    how to draw a good districting plan, or how to map out your
                    neighborhood or region of the state.
                  </p>
                  <p>
                    When you're ready, head to Districtr and
                    give mapping tools a try!
                  </p>

                  <div className="text-center m-5 button-group">
                    <Button
                      variant="plan"
                      href="https://districtr.org/portal/mi/blank_congress"
                      target="_blank"
                    >
                      <strong>
                        Map Congressional Districts <br /> (opens in Districtr)
                      </strong>
                    </Button>{" "}
                    <Button
                      variant="plan"
                      href="https://districtr.org/portal/mi/blank_senate"
                      target="_blank"
                    >
                      <strong>
                        Map State Senate Districts <br /> (opens in Districtr)
                      </strong>
                    </Button>{" "}
                    <Button
                      variant="plan"
                      href="https://districtr.org/portal/mi/blank_house"
                      target="_blank"
                    >
                      <strong>
                        Map State House Districts <br /> (opens in Districtr)
                      </strong>
                    </Button>{" "}
                    <Button
                      variant="coi"
                      href="https://districtr.org/portal/mi/blank_coi"
                      target="_blank"
                    >
                      <strong>
                        Map a Community of Interest
                        <br /> (opens in Districtr)
                      </strong>
                    </Button>{" "}
                    {/* <Button className="custom" href="https://districtr.org/michigan" target="_black">More Options<br /> (opens in Districtr)</Button> */}
                  </div>
                </div>
              </div>

              <div className="jump" id="gallery"></div>
              <Anchor>Submission Gallery</Anchor>

              <Gallery
                filterTags={filterTags}
                setFilterTags={setFilterTags}
                refresh={refresh}
              />
              </>
            }/>
          </Routes>
        </div>
      </div>
    </Router>
  );
};
