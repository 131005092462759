import { useState, useEffect } from "react";

import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import InputTags from "../InputTags/InputTags";
import DraftFormTabs from "../DraftPlans/DraftFormTabs";

export default function DraftFormTestimony (props) {
  const { register, tags, setTags, type, setType } = props;

  let urlinit = (window.location.href.split("id=")[1] || "")
    .split("&")[0]
    .split("#")[0];
  const [prefillid, setPrefillid] = useState(
    urlinit ? "https://districtr.org/edit/" + urlinit : null
  );

  useEffect(() => {
    if (window.location.href.includes("planid=")) {
      const prefill =
        "https://districtr.org/plan/" +
        window.location.href.split("planid=")[1].split("&")[0].split("#")[0];
      setType("plan");
      if (!isNaN(prefill)) {
        setPrefillid(prefill);
      }
      if (window.location.hash) {
        document.documentElement.scrollTop =
          document.getElementById(window.location.hash.substring(1)).offsetTop -
          40;
      }
    } else if (window.location.href.includes("coiid=")) {
      const prefill =
        "https://districtr.org/COI/" +
        window.location.href.split("coiid=")[1].split("&")[0].split("#")[0];
      setType("coi");
      if (!isNaN(prefill)) {
        setPrefillid(prefill);
      }
      if (window.location.hash) {
        document.documentElement.scrollTop =
          document.getElementById(window.location.hash.substring(1)).offsetTop -
          40;
      }
    } else if (window.location.href.includes("commentid=")) {
      const prefill = window.location.href
        .split("commentid=")[1]
        .split("&")[0]
        .split("#")[0];
      if (!isNaN(prefill.slice(1))) {
        setPrefillid(prefill);
      }
      setType("comment");
    }
  }, [setType]);

  return (
    <div>
      <Row>
        <Form.Group as={Col} controlId="title">
          <Form.Label>
            <strong>Submission Title*</strong>
          </Form.Label>

          <Form.Control
            {...register('title', { required: true })}
            defaultValue=""
            disabled={type === "comment"}
            type="text"
            className="testimony"
            placeholder={
              type === "comment"
                ? "Written response to draft plan"
                : "Example: Response to House Concept A"
            }
            maxLength="40"
            style={{ width: "75%" }}
          />
        </Form.Group>
      </Row>
      <DraftFormTabs
        prefillid={prefillid}
        setPrefillid={setPrefillid}
        register={register}
        type={type}
        setType={setType}
        setBlockSubmit={props.setBlockSubmit}
        districtrContent={props.districtrContent}
        setDistrictrContent={props.setDistrictrContent}
      />
      <br />
      <br />
      <strong>Tags</strong>
      <br /> Add tags to your submission here to help people search for it.
      These can be regions or cities, communities, identities, organizations, or
      anything else.
      <br />
      (Examples: #yoopers, #kalamazoo, #hispanic, #vfw, #lwv).
      <InputTags tags={tags} setTags={setTags} controlId="formtags" />
    </div>
  );
};
