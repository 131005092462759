import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { getPreviewsBySearch } from "../../api.js";

import GalleryCard from "../GalleryCard/GalleryCard.jsx";
import GalleryCardIcon from "../GalleryCard/GalleryCardIcon.jsx";
import AdvancedSearch from "../Search/AdvancedSearch.jsx";
import GalleryPaging from "../Gallery/GalleryPaging.jsx";
import Anchor from "../Anchor/Anchor.jsx";

import CardGroup from "react-bootstrap/CardGroup";
import Badge from "react-bootstrap/Badge";
import Table from "react-bootstrap/Table";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";

export default function SearchPage () {
  const numIcons = 30;
  const numCards = 12;
  const numListItems = 12;
  const [page, setPage] = useState(1);
  const [length, setLength] = useState(numCards);
  const [previews, setPreviews] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [galleryType, setGalleryType] = useState("cards");
  const [keyword, setKeyword] = useState("");
  const [author, setAuthor] = useState("");
  const [minDate, setMinDate] = useState("");
  const [maxDate, setMaxDate] = useState("");
  const [link, setLink] = useState("");
  const [type, setType] = useState("");
  const [tag, setTag] = useState("");
  const [numMatches, setNumMatches] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    getPreviewsBySearch(
      keyword,
      author,
      type,
      minDate,
      maxDate,
      link,
      "",
      [tag],
      page,
      length
    ).then((res) => {
      if (res.matches) {
        setNumMatches(res.matches);
      }
      setPreviews(Object.values(res.submissionPreviews).filter(p => p.first !== "ND"));
    });
  }, [page, length]);

  const plans = previews;

  const toggleView = (val) => {
    setGalleryType(val);
    setLength(
      val === "icon" ? numIcons : val === "cards" ? numCards : numListItems
    );
    setPage(1);
  };

  return (
    <div className="details">
      <Anchor>Search Submissions</Anchor>
      <div className="m-5">
        <div className="text-center m-5">
          <ButtonGroup
            name="gal"
            type="radio"
            value={galleryType}
          >
            <Button onClick={() => toggleView("cards")}>Card View</Button>
            <Button onClick={() => toggleView("list")}>List View</Button>
            <Button onClick={() => toggleView("icon")}>Icon View</Button>
          </ButtonGroup>
        </div>

        <AdvancedSearch
          show={modalShow}
          onHide={() => setModalShow(false)}
          setPage={setPage}
          setNumMatches={setNumMatches}
          length={length}
          setPreviews={setPreviews}
          keyword={keyword}
          setKeyword={setKeyword}
          author={author}
          setAuthor={setAuthor}
          minDate={minDate}
          setMinDate={setMinDate}
          maxDate={maxDate}
          setMaxDate={setMaxDate}
          link={link}
          setLink={setLink}
          type={type}
          setType={setType}
          tag={tag}
          setTag={setTag}
        />
        {(
          <div>
            {galleryType === "cards" ? (
              <CardGroup className="gallery">
                {plans.map((plan, i) => (
                  <li key={i} style={{ listStyle: "none" }}>
                    <GalleryCard
                      title={plan.title || "TITLE"}
                      name={plan.first + " " + plan.last}
                      loc={plan.city}
                      id={plan.type[0] + plan.id || "id001"}
                      type={plan.type || "coi"}
                      datetime={
                        new Date(plan.datetime).toLocaleDateString() || "3/25/2021"
                      }
                      img={plan.img || ""}
                      link={plan.link || ""}
                      numberOfComments={plan.numberofcomments || 0}
                      tags={plan.tags || []}
                      filterTags={null}
                      setFilterTags={null}
                      handleTagClick={() => {}}
                    />
                  </li>
                ))}
              </CardGroup>
            ) : galleryType === "icon" ? (
              <CardGroup className="icons">
                {plans.map((plan, i) => (
                  <li key={i} style={{ listStyle: "none" }}>
                    <GalleryCardIcon
                      title={plan.title || "TITLE"}
                      id={plan.type[0] + plan.id || "id001"}
                      type={plan.type || "coi"}
                      link={plan.link || ""}
                    />
                  </li>
                ))}
              </CardGroup>
            ) : (
              <Table hover className="list-view">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Submitter</th>
                    <th>Date</th>
                    <th>ID</th>
                    <th>Tags</th>
                    <th>Comments</th>
                  </tr>
                </thead>
                <tbody>
                  {plans.map((plan, i) => (
                    <tr
                      onClick={() =>
                        navigate("/submission/" + plan.type[0] + plan.id)
                      }
                      key={i}
                    >
                      <td className={plan.type}>
                        <a
                          className="preview"
                          href={"/submission/" + plan.type[0] + plan.id}
                        >
                          <b>{plan.title}</b>
                        </a>
                      </td>
                      <td>
                        {plan.first} {plan.last} – {plan.city}
                      </td>
                      <td>
                        {new Date(plan.datetime).toLocaleDateString() || "3/25/2021"}
                      </td>
                      <td>{plan.type[0] + plan.id}</td>
                      <td>
                        <div className="tags-expo">
                          {(plan.tags || []).map((tag, i) => (
                            <a className="post-tag" onClick={() => {}} key={i}>
                              #{tag}
                            </a>
                          ))}
                        </div>
                      </td>
                      <td className="text-muted">
                        Comments:{" "}
                        <Badge variant={plan.type}>
                          {plan.numberofcomments || 0}
                        </Badge>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </div>
        )}
        <GalleryPaging
          page={page}
          setPage={setPage}
          length={length}
          numMatches={numMatches}
        />
      </div>
    </div>
  );
};
