import { useState, useEffect, useLayoutEffect, useRef } from "react";
import { useForm } from "react-hook-form";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

import FormPersonal from "../FormPersonal/FormPersonal.jsx";
import FormTestimony from "../FormTestimony/FormTestimony.jsx";
import Anchor from "../Anchor/Anchor.jsx";

import {
  postSubmission,
  getSignedUrl,
  getToken,
  uploadSubmission,
  MAX_DOCUMENT_SIZE_MB,
} from "../../api.js";

export function FormParent (props) {
  const { register, handleSubmit /*{ watch, errors}*/ } = useForm();
  const { refresh, triggerRefresh } = props;

  const [behalf, setBehalf] = useState("no");
  const [tags, setTags] = useState([]);
  const [placeTags, setPlaceTags] = useState([]);
  const [type, setType] = useState("written");
  const [serverResponse, setServerResponse] = useState(null);
  const [canSubmit, setCanSubmit] = useState(true);
  const [blockSubmit, setBlockSubmit] = useState(false);
  const [districtrContent, setDistrictrContent] = useState();

  const focuser = useRef(null);
  useEffect(() => {
    focuser.current && focuser.current.focus();
  }, [focuser, serverResponse]);

  // token for GET-POST continuity
  const [token, setToken] = useState();
  // token for EMAIL/POST history
  let oldToken = null;
  if (window.localStorage && window.localStorage.getItem) {
    oldToken = window.localStorage.getItem("mem_token");
  } else if (
    document.cookie &&
    document.cookie.length &&
    document.cookie.includes("mem_token=")
  ) {
    oldToken = document.cookie
      .split("mem_token=")[1]
      .split(";")[0]
      .split(" ")[0];
  }
  const [mem_token, setMemToken] = useState(oldToken);

  window.locationMark = "none";

  const onRefresh = () => {
    Array.from(document.getElementsByClassName("testimony")).forEach(
      (input) => (input.value = "")
    );
    setServerResponse(null);
    setCanSubmit(true);
    setDistrictrContent(null);
    triggerRefresh(!refresh);
  };

  const createAndUploadDocument = (file, uploadURL, mimeType) => {
    let reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException("Error reading file"));
      };
      reader.onload = async (e) => {
        console.log("Read file of length: " + e.target.result.length);
        if (e.target.result.length > MAX_DOCUMENT_SIZE_MB * 1048576) {
          const msg = `File exceeds ${MAX_DOCUMENT_SIZE_MB}MB`;
          reject(new DOMException(msg));
        }
        const document = e.target.result;
        try {
          await uploadDocument(document, uploadURL, mimeType);
          resolve();
        } catch (err) {
          reject(err);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  // Save a document to a presigned S3 URL
  // The document is a data URL
  const uploadDocument = async (document, uploadURL, mimeType) => {
    // Strip off the beginning, which looks something like
    // data:image/png;base64,
    let binary = atob(document.split(",")[1]);
    let array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    let blobData = new Blob([new Uint8Array(array)], { type: mimeType });
    console.log("Uploading to: ", uploadURL);
    await uploadSubmission(uploadURL, blobData, mimeType);
  };

  const onSubmit = async (data) => {
    // alert(JSON.stringify({...data, type: type, tags: tags}))
    if (!data.token) {
      data.token = token;
    }
    setBlockSubmit(true);
    const files = data.file;
    let firstStep;
    if (files && files.length > 0) {
      // User requested a File Upload
      const file = files[0];
      const response = await getSignedUrl(file.name);
      const { uploadURL, mimeType } = response;
      data.link = uploadURL.split("?")[0];
      firstStep = createAndUploadDocument(file, uploadURL, mimeType);
    } else {
      firstStep = Promise.resolve();
    }
    firstStep
      /* eslint-disable-next-line */
      .then((result) => postSubmission({ ...data, type: type, tags: tags }))
      .then((res) => {
        setCanSubmit(false);
        setBlockSubmit(false);
        const postStatement =
          res.newtoken && res.newtoken.length
            ? "Your post was received. Posts will generally be made public in 1-2 business days."
            : "Successfully submitted.";
        const fileStatement =
          files && files.length > 0
            ? "Your file was successfully uploaded."
            : "";
        setServerResponse(
          <Alert
            variant="success"
            tabIndex="0"
            onClose={() => setServerResponse(null)}
            dismissible
            ref={focuser}
          >
            {postStatement} {fileStatement}
          </Alert>
        );
        if (res.newtoken && res.newtoken.length) {
          if (window.localStorage && window.localStorage.setItem) {
            window.localStorage.setItem("mem_token", res.newtoken);
          } else if (typeof document.cookie === "string") {
            document.cookie = "mem_token=" + res.newtoken;
          } else {
            console.error("Nowhere to save token");
          }
          setMemToken(res.newtoken);
        }
      })
      .catch((err) => {
        setBlockSubmit(false);
        setServerResponse(
          <Alert
            variant="danger"
            dismissible
            onClose={() => setServerResponse(null)}
          >
            Something went wrong. Please try again later. Error: {err.message}.
            Contact portals@mggg.org if you need support with the submission
            process.{" "}
          </Alert>
        );
      });
  };

  const onKeyPress = (event) => {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  };

  useLayoutEffect(() => {
    if (!token) {
      getToken().then(setToken);
    }
  }, [ ]);

  return (
    <div className="m-5">
      <Form
        onKeyPress={onKeyPress}
        onSubmit={handleSubmit(onSubmit)}
        className="p-5"
        style={{ border: "1px solid black", borderRadius: "0.25rem" }}
      >
        <Anchor size="1.2" id="form">
          Submission Form
        </Anchor>
        <p className="m-3">
          Subject to content moderation, submissions will be publicly displayed
          in the gallery below.
          <br />
          Fields marked with a star (*) are required.
        </p>
        <Form.Control
          {...register('token')}
          aria-label="Hidden web token code"
          defaultValue={token}
          style={{ display: "none" }}
        />
        <Form.Control
          {...register('mem_token')}
          aria-label="Hidden email token code"
          defaultValue={mem_token}
          style={{ display: "none" }}
        />
        <div className="m-5">
          <FormTestimony
            register={register}
            behalf={behalf}
            setBehalf={setBehalf}
            tags={tags}
            setTags={setTags}
            placeTags={placeTags}
            setPlaceTags={setPlaceTags}
            type={type}
            setType={setType}
            setBlockSubmit={setBlockSubmit}
            districtrContent={districtrContent}
            setDistrictrContent={setDistrictrContent}
          />
        </div>
        <Anchor size="1.2">Tell Us About Yourself</Anchor>
        <p className="m-3">
          The Commission invites wide public participation from Michiganders all
          across our great state. Please provide information about yourself in
          the fields below.
        </p>

        <div className="m-5">
          <FormPersonal register={register} />
        </div>

        <Form.Group controlId="formCheckboxPublic">
          <Form.Check
            required
            type="checkbox"
            label="I understand that my public comment submission will be made available to the Commission and other members of the public."
            feedback="You must agree before submitting."
          />
        </Form.Group>
        <Form.Group controlId="formCheckboxConfEmail">
          <Form.Check
            required
            type="checkbox"
            label="I understand that while this public comment submission is a public document, my email address will be kept confidential to the extent authorized by law."
            feedback="You must agree before submitting."
          />
        </Form.Group>
        {serverResponse ? serverResponse : ""}
        {canSubmit ? (
          <Button type="submit" className="submit" disabled={blockSubmit}>
            Submit
          </Button>
        ) : (
          <>
            <Button type="button" onClick={onRefresh}>
              Refresh
            </Button>
            <Button
              type="submit"
              className="submit"
              style={{ display: "none" }}
              disabled={blockSubmit}
            >
              Submit
            </Button>
          </>
        )}
      </Form>
    </div>
  );
}
