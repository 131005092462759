const Filter = require('bad-words'),
    wordfilter = new Filter();
wordfilter.addWords('Antarctican');
wordfilter.removeWords('Dick');
wordfilter.removeWords('god');
wordfilter.removeWords('gods');
wordfilter.removeWords('God');
wordfilter.removeWords('Gods');

export default function clearText (txt) {
  if (!txt || !txt.trim().length) {
    return '';
  }
  // return "filter applied";
  return wordfilter.clean(('A ' + txt).replace(/[    ]/g, '')).replace(/\*+/g, '*').substring(2);
}
