import axios from 'axios';

//const EAST1DEV = "https://okbbtiwhui.execute-api.us-east-1.amazonaws.com/dev/submissions"
const EAST2DEV = "https://qp2072772f.execute-api.us-east-2.amazonaws.com/dev/submissions"
const EAST2MAIN = "https://o1siz7rw0c.execute-api.us-east-2.amazonaws.com/prod/submissions"

const ENDPOINT = (window.location.href.includes("develop") || window.location.href.includes("localhost"))
    ? EAST2DEV
    : EAST2MAIN;

export const MAX_DOCUMENT_SIZE_MB = 15;

export const postSubmission = (data) => {

    // patching a type error here for now
    // submission for draft comments needs a value
    if (!data.submission)
        data.submission = "d0"

    return axios
        .post(ENDPOINT, data)
        .then(res => res.data)
}

export const getSignedUrl = (fileName) => {
    return axios
        .get(`${ENDPOINT}/getSignedURL`, { params: { file: fileName } })
        .then(res => res.data)
}

export const uploadSubmission = (signedUrl, blobData, mimeType) => {

    return axios(signedUrl, {
        method: 'PUT',
        headers: {
            'Content-Type': mimeType
        },
        data: blobData
    })
}
/**
 * Given a date string that represents a local date, return a Date object that is the UTC datetime corresponding to the beginning of that day
 * in the current locale.  For example, an EST a string of '2021-01-03' should convert to a Date  of  2021-01-03T05:00:00Z
 * @param {date string in the format YYYY-MM-DD} s
 */
function parseLocalDate(s) {
    const now = new Date()
    let d = new Date(Date.parse(s) + now.getTimezoneOffset() * 1000 * 60)
    return d
}
export const getPreviewsBySearch = (keyword = "", author = "", type = "", minDate = "", maxDate = "", link="", draft="", tags = [], page = 1, length = 12, showText = false) => {
    if (minDate) {
        minDate = parseLocalDate(minDate).toISOString()
    }
    if (maxDate) {
        const d = parseLocalDate(maxDate)
        d.setDate(d.getDate() + 1)
        maxDate = d.toISOString()
    }
    return axios
        .get(`${ENDPOINT}/search?keyword=${keyword}&author=${author}&type=${type}&minDate=${minDate}&maxDate=${maxDate}&link=${link}&draft=${draft}&tags=${tags.join('&tags=')}&page=${page}&length=${length}&showText=${showText}`)
        .then(res => res.data)
}


export const getDetails = (id) => {
    return axios
        .get(`${ENDPOINT}/${id}`)
        .then(res => res.data)
}

export const getPreview = (id) => {
    return axios
        .get(`https://districtr.org/.netlify/functions/planPreview?id=${id}`)
        .then(res => res.data)
}

export const getDraftComments = (draft) => {
    return axios
        .get(`${ENDPOINT}/draft/${draft}`)
        .then(res => res.data)
}

export const movePage = (page, direction) => {
    return axios
        .get(`${ENDPOINT}/move?id=${page}&direction=${direction}`)
        .then(res => res.data)
}

export const getToken = () => {
    return axios
        .get(`${ENDPOINT}/token`)
        .then(res => res.data)
}
