import React, { useState, useEffect } from "react";

import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";

import clearText from "../../clearText";
import { getPreview } from "../../api";

import "./GalleryCard.scss";

import iconWritten from "../../assets/chat.png";
import iconLink from "../../assets/link.png";

const GalleryCard = (props) => {
  const {
    title,
    name,
    loc,
    id,
    type,
    numberOfComments,
    datetime,
    tags,
    link,

    handleTagClick,
  } = props;

  const [image, setImage] = useState(iconWritten);
  const [districttype, setDistrictType] = useState(null);

  useEffect(() => {
    if (type === "plan" || type === "coi") {
      let did = link.split("/").slice(-1)[0];
      did = did.split("?")[0].split("&")[0];
      if (did.length) {
        getPreview(did).then((api) => {
          if (api.screenshot) {
            setImage(api.screenshot);
          }
          if (api.plan && api.plan.problem && api.plan.problem.pluralNoun) {
            setDistrictType(api.plan.problem.pluralNoun);
          }
        });
      }
    } else if (type === "written") {
      setImage(iconWritten);
    } else if (type === "other" || type === "file") {
      setImage(iconLink);
    }
  }, [link, type]);

  const getType = (type) => {
    return type;
  };

  return (
    <a className="preview" href={"/submission/" + id}>
      <Card className="shadow p-3 mb-5 bg-white" border={getType(type)}>
        <Card.Body>
          <Card.Img
            variant="top"
            alt={`Preview image for submission ${id}`}
            src={image}
            height="164"
            width="200"
          />
          <hr />
          <Card.Title className={getType(type)}>
            <strong>{clearText(title)}</strong>
          </Card.Title>
          <Card.Subtitle>
            <strong>{clearText(name)}</strong>
            {loc ? <> - {clearText(loc)}</> : ""}
          </Card.Subtitle>
          <Card.Text>
            <small>
              {datetime} <br />
              Type: {clearText(type)} | ID: {id}
              <br />
              {type === "plan" && districttype ? (
                <>
                  {clearText(districttype.replace("Reapportioned ", ""))}
                  <br />
                </>
              ) : null}
              {tags.length > 0 ? (
                <span className="tags-expo">
                  {tags.slice(0, 3).map((tag, i) => (
                    <Card.Link
                      className="post-tag"
                      onClick={(e) => handleTagClick(e, clearText(tag))}
                      key={i}
                    >
                      #{clearText(tag)}
                    </Card.Link>
                  ))}
                  {tags.length > 3 ? <span>...</span> : ""}
                </span>
              ) : null}
            </small>
          </Card.Text>
        </Card.Body>
        <Card.Footer className="text-muted">
          <small>
            Comments: <Badge variant={getType(type)}>{numberOfComments}</Badge>
          </small>
        </Card.Footer>
      </Card>
    </a>
  );
};

export default GalleryCard;
