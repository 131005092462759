import { useState } from 'react'
import {isMobileOnly} from 'react-device-detect';

import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ListGroup from "react-bootstrap/ListGroup";

import options from '../../assets/locations'


const UsaStates = require("usa-states").UsaStates;
const states = new UsaStates({
    includeTerritories: true,
});


export default function FormPersonal (props) {
    const { register } = props;

    const [city, setCity] = useState("");
    const [active, setActive] = useState(1)
    const [completions, setCompletions] = useState([])


    const handleClick = (c) => {
        setCity(c)
    }

    const handleBlur = () => {
        setCompletions([])
    }

    const handleChange = (e) => {
        let val = e.target.value;
        setCity(val)
        setActive(-1)
        setCompletions(val.length ? options.filter(option => option.toLowerCase().startsWith(val.toLowerCase())).slice(0, 5) : [])
    }


    const handleKeyDown = (e) => {
        let val = e.target.value;
        if (e.key === 'Enter') {
            if (active > -1) {
                setCity(completions[active])
            } else if (val) {
                setCity(val)
            }
            setCompletions([])
        } else if (e.key === 'ArrowUp') {
            setActive(active => Math.max(active - 1, -1));
        } else if (e.key === 'ArrowDown') {
            setActive(active => Math.min(active + 1, completions.length));
        }
    }

    return (<div>
        <Row className="align-items-end">
            <Form.Group as={Col} xs={2} controlId="formGridPrefix">
                <Form.Label className="hide-small">Salutation</Form.Label>
                <Form.Control
                  {...register('salutation')}

                 placeholder="Ms/Mr/Mx/Dr" maxLength="10"/>
            </Form.Group>
            <Form.Group as={Col} controlId="formGridFirstName">
                <Form.Label>First Name (or identifier)*</Form.Label>
                <Form.Control
                  {...register('first', { required: true })}
                 maxLength="30"/>
            </Form.Group>
            <Form.Group as={Col} controlId="formGridLastName">
                <Form.Label>Last Name (optional)</Form.Label>
                <Form.Control
                  {...register('last')}

                 maxLength="30"/>
            </Form.Group>
        </Row>
        <Row>
            <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Email*</Form.Label>
                <Form.Control
                  {...register('email', { required: true })}
                  type="email" maxLength="255"/>
            </Form.Group>
        </Row>
        <Row>

            <Form.Group as={Col} controlId="formGridCity">
                <Form.Label>City/County <span className="d-none d-lg-inline-block" style={{fontWeight: "normal"}}>{isMobileOnly ? "" : "(optional but encouraged)"}</span></Form.Label>
                <Form.Control
                  {...register('city', { required: false })}
                  maxLength="255"/>
            </Form.Group>

            <Form.Group as={Col} md={3} controlId="formGridState">
                <Form.Label>State</Form.Label>
                <Form.Control
                {...register('state')}
                as="select" defaultValue="Choose...">
                    <option>Michigan</option>
                    {states.states.map((s, i) => (
                        <option key={i}>{s.name}</option>
                    ))}
                </Form.Control>
            </Form.Group>

            <Form.Group as={Col} xs={3} controlId="formGridZip">
                <Form.Label>Zip</Form.Label>
                <Form.Control
                {...register('zip')}
                 maxLength="10"/>
            </Form.Group>
        </Row>
    </div>)
}
